import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CompleteIcon from '@material-ui/icons/CheckCircle';
import QueuedIcon from '@material-ui/icons/AccessTime';
import ErroredIcon from '@material-ui/icons/Error';
import {
  NumberFormat,
  Label,
  ModelConfigurationDetails,
  ModelSetModal
} from 'components';
import moment from 'moment';
import {
  Card,
  CardContent,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CardHeader,
  Divider,
  colors,
  Link,
  Button,
  LinearProgress,
  Tooltip
} from '@material-ui/core';
import {
  findModelAnalysisComparison,
  findModelAnalysisComparisonSilently,
  recalculateModelAnalysisComparison
} from 'actions';
import {
  CELL_MODELS,
  MODEL_ANALYSIS_SESSION_SOURCES,
  DEFAULT_SUB_MODEL_AREA_DESCRIPTIONS,
  MODEL_ANALYSIS_PROCESSING_STATUS
} from 'common/constants';
import { Link as RouterLink } from 'react-router-dom';
import ws from 'utils/ws';
import { Search } from 'components/SearchBar/components';

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0
  },
  container: {
    maxHeight: 400
  },
  columnSeparator: {
    borderLeftWidth: 1,
    borderLeftColor: colors.grey[400],
    borderLeftStyle: 'dotted'
  },
  modelName: {
    maxWidth: 360,
    overflowX: 'hidden',
    textOverflow: 'ellipsis'
  },
  indicator: {
    cursor: 'help',
    display: 'inline-block',
    backgroundColor: colors.blue[600],
    height: 10,
    borderRadius: 6
  },
  actionButton: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  marginDetail: {
    marginLeft: theme.spacing(1)
  },
  modelEventCount: {
    color: colors.blueGrey[600]
  },
  subHeaderInfoIcon: {
    fontSize: 16,
    marginBottom: -3,
    color: colors.blue[600],
    marginRight: theme.spacing(0.5)
  },
  asWalkedInfoIcon: {
    cursor: 'help',
    fontSize: 16,
    marginBottom: -3,
    color: colors.blue[600],
    marginLeft: theme.spacing(1)
  },
  modelSetInfoIcon: {
    cursor: 'pointer',
    fontSize: 16,
    marginBottom: -3,
    color: colors.blue[600],
    marginLeft: theme.spacing(1)
  },
  valueInfoIcon: {
    fontSize: 16,
    marginBottom: -3,
    color: colors.blue[600],
    marginLeft: theme.spacing(1),
    cursor: 'pointer'
  },
  asWalkedRow: {
    backgroundColor: '#FFFDE5'
  },
  modelSetRow: {
    backgroundColor: 'rgba(211, 33, 155, 0.05)'
  }
}));

const ModelAnalysisComparison = props => {
  const { sourceType, sourceId } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    modelAnalysisComparison,
    loading,
    silentlyLoading,
    lastUpdatedTime
  } = useSelector(state => state.modelAnalysisComparison);

  const [detailsModelConfiguration, setDetailsModelConfiguration] = useState(
    null
  );
  const [detailsModelSet, setDetailsModelSet] = useState(null);
  const [modelSearch, setModelSearch] = useState('');
  const POLLING_FREQUENCY = 5000;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      dispatch(findModelAnalysisComparison(sourceType, sourceId));
    }

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (!loading && !silentlyLoading) {
      const intervalId = setInterval(() => {
        dispatch(findModelAnalysisComparisonSilently(sourceType, sourceId));
      }, POLLING_FREQUENCY);

      return () => clearInterval(intervalId);
    }
  }, [modelAnalysisComparison]);

  const displaySessionCount =
    sourceType === MODEL_ANALYSIS_SESSION_SOURCES.SESSION ||
    sourceType === MODEL_ANALYSIS_SESSION_SOURCES.SESSION_GROUP;
  const displayCustomerEventCount =
    sourceType === MODEL_ANALYSIS_SESSION_SOURCES.CUSTOMER_EVENT ||
    sourceType === MODEL_ANALYSIS_SESSION_SOURCES.SESSION_GROUP;
  const sortedAnalyses = (
    modelAnalysisComparison.modelConfigurationAnalyses || []
  ).filter(
    ma =>
      modelSearch.length === 0 ||
      (ma.modelSet?.name &&
        ma.modelSet?.name?.toUpperCase()?.indexOf(modelSearch.toUpperCase()) >
          -1) ||
      (ma.modelConfiguration?.displayName &&
        ma.modelConfiguration?.displayName
          ?.toUpperCase()
          .indexOf(modelSearch.toUpperCase()) > -1)
  );
  const firstAnalysis = sortedAnalyses[0];

  const completeCount = modelAnalysisComparison.modelConfigurationAnalyses
    ? modelAnalysisComparison.modelConfigurationAnalyses.filter(
        ma => ma.calculated
      ).length
    : 0;
  const incompleteCount = modelAnalysisComparison.modelConfigurationAnalyses
    ? modelAnalysisComparison.modelConfigurationAnalyses.filter(
        ma => !ma.calculated
      ).length
    : 0;
  const totalCount = modelAnalysisComparison.modelConfigurationAnalyses
    ? modelAnalysisComparison.modelConfigurationAnalyses.length
    : 0;

  sortedAnalyses.sort((analysis1, analysis2) => {
    return (
      (analysis1.scoreRank !== null
        ? analysis1.scoreRank
        : Number.MAX_SAFE_INTEGER) -
      (analysis2.scoreRank !== null
        ? analysis2.scoreRank
        : Number.MAX_SAFE_INTEGER)
    );
  });

  const handleCloseModelDetails = () => {
    setDetailsModelConfiguration(null);
  };

  const handleRecalculate = () => {
    dispatch(recalculateModelAnalysisComparison(sourceType, sourceId));
  };

  const handleShowModelSetModal = modelSet => {
    ws.get(`/model_sets/${modelSet.id}`).then(response => {
      setDetailsModelSet(response.data.result);
    });
  };

  const handleDownload = () => {
    window.location = ws.url(
      `/model_analysis_comparisons/${sourceType}/${sourceId}/download`
    );
  };

  const RankLabel = props => {
    const { rank, lowest, primaryRank } = props;
    return (
      <Label
        color={
          lowest
            ? !primaryRank
              ? colors.yellow[800]
              : colors.blue[600]
            : colors.grey[500]
        }>
        {rank}
      </Label>
    );
  };

  const ProcessingStatus = props => {
    const { modelAnalysisComparison } = props;
    const processingStatus = modelAnalysisComparison.processingStatus;

    if (!processingStatus) return null;

    const statusColors = {
      COMPLETED: colors.green[600],
      PROCESSING: colors.blue[600],
      QUEUED: colors.orange[600],
      ERRORED: colors.red[600]
    };

    return (
      <Label
        color={statusColors[processingStatus]}
        style={{ borderRadius: 12, paddingRight: 0, marginRight: 8 }}>
        {MODEL_ANALYSIS_PROCESSING_STATUS[processingStatus]}
        {processingStatus === 'COMPLETED' && (
          <CompleteIcon
            style={{
              color: 'white',
              height: 14
            }}
          />
        )}
        {processingStatus === 'PROCESSING' && (
          <CircularProgress
            style={{ marginLeft: 4, marginRight: 6 }}
            size={10}
            color={'white'}
          />
        )}
        {processingStatus === 'QUEUED' && (
          <QueuedIcon
            style={{
              color: 'white',
              height: 14
            }}
          />
        )}
        {processingStatus === 'ERRORED' && (
          <ErroredIcon
            style={{
              color: 'white',
              height: 14
            }}
          />
        )}
      </Label>
    );
  };

  return (
    <Card>
      <CardHeader
        title={'Model Analysis '}
        subheader={
          !loading && (
            <>
              <>
                {modelAnalysisComparison?.calculatedTime
                  ? 'Last calculated on ' +
                    moment(modelAnalysisComparison.calculatedTime).format(
                      'MMM Do YYYY, h:mm:ss a'
                    )
                  : ''}
              </>

              <>
                {modelAnalysisComparison?.processingStatus !== 'ERRORED' &&
                modelAnalysisComparison?.processingStatus !== 'COMPLETED' &&
                lastUpdatedTime
                  ? ' (status last updated at ' +
                    moment(lastUpdatedTime).format('h:mm:ss a') +
                    ', updates every 5 seconds)'
                  : ''}
              </>

              <>
                {modelAnalysisComparison.eventCellModels &&
                  modelAnalysisComparison.eventCellModels.length > 0 && (
                    <>
                      . Walk Cell Types:{' '}
                      {modelAnalysisComparison.eventCellModels
                        .map(cm => CELL_MODELS[cm])
                        .join(', ')}
                    </>
                  )}
              </>
              <>
                {modelAnalysisComparison.eventCellModels &&
                  modelAnalysisComparison.eventCellModels.length > 0 && (
                    <>
                      . Score:{' '}
                      {modelAnalysisComparison.subModel >= 0
                        ? DEFAULT_SUB_MODEL_AREA_DESCRIPTIONS[
                            modelAnalysisComparison.subModel
                          ]
                        : 'Combined'}
                    </>
                  )}
              </>
              <div>
                {!modelAnalysisComparison.calculating &&
                  modelAnalysisComparison.analysisEventCountsConsistent &&
                  firstAnalysis &&
                  (displaySessionCount
                    ? firstAnalysis.sessionCount +
                      ' Test Session' +
                      (firstAnalysis.sessionCount !== 1 ? 's' : '') +
                      ', '
                    : '') +
                    (displayCustomerEventCount
                      ? firstAnalysis.customerEventCount +
                        ' Customer Event' +
                        (firstAnalysis.customerEventCount !== 1 ? 's' : '') +
                        ', '
                      : '') +
                    firstAnalysis.eventCount +
                    ' Walk' +
                    (firstAnalysis.eventCount !== 1 ? 's' : '') +
                    ' ' +
                    '(' +
                    firstAnalysis.objectEventCount +
                    ' Object, ' +
                    firstAnalysis.noObjectEventCount +
                    ' No Object)'}
              </div>
              <>
                {modelAnalysisComparison.calculated &&
                  !modelAnalysisComparison.calculating &&
                  !modelAnalysisComparison.analysisEventCountsConsistent && (
                    <>
                      <InfoIcon className={classes.subHeaderInfoIcon} />
                      Inconsistent Model Event Counts
                    </>
                  )}
              </>
            </>
          )
        }
        action={
          <>
            <Button
              size="small"
              className={classes.actionButton}
              variant="outlined"
              color="primary"
              onClick={handleDownload}
              disabled={!modelAnalysisComparison.calculatedTime || loading}>
              Download
            </Button>
            <Button
              size="small"
              className={classes.actionButton}
              variant="outlined"
              color="primary"
              onClick={handleRecalculate}
              disabled={
                modelAnalysisComparison.processingStatus === 'PROCESSING' ||
                modelAnalysisComparison.processingStatus === 'QUEUED' ||
                loading
              }>
              Recalculate
            </Button>
          </>
        }
      />
      <Divider />
      <CardContent className={classes.content}>
        {loading && <LinearProgress />}

        {modelAnalysisComparison && (
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={4}>
                    {' '}
                    <ProcessingStatus
                      modelAnalysisComparison={modelAnalysisComparison}
                    />{' '}
                    {modelAnalysisComparison.processingStatus ===
                      'PROCESSING' && (
                      <>
                        <NumberFormat
                          value={
                            modelAnalysisComparison.processingCompletionPercentage >
                            0.99
                              ? 0.99
                              : modelAnalysisComparison.processingCompletionPercentage
                          }
                          percentage={true}
                          fixedDecimalScale={true}
                          precision={0}
                          suffix={'% Complete'}
                        />

                        {' (' + completeCount + ' of ' + totalCount + ')'}
                      </>
                    )}
                    {modelAnalysisComparison.processingStatus === 'QUEUED' && (
                      <>
                        {'Queued on'}{' '}
                        {moment(modelAnalysisComparison.queuedTime).format(
                          'MMM Do YYYY, h:mm a'
                        )}
                        {modelAnalysisComparison.queuePosition
                          ? ', Position ' +
                            modelAnalysisComparison.queuePosition
                          : ''}
                      </>
                    )}
                  </TableCell>
                  <TableCell colSpan={3} className={classes.columnSeparator}>
                    Score Components
                  </TableCell>
                  <TableCell className={classes.columnSeparator}>
                    Object Present
                  </TableCell>
                  <TableCell className={classes.columnSeparator}>
                    No Object
                  </TableCell>
                  <TableCell colSpan={6} className={classes.columnSeparator} />
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Search
                      autoFocus={true}
                      placeholder="Model/Model Set"
                      value={modelSearch}
                      onSearchValueChange={value => setModelSearch(value)}
                      className={classes.search}
                      hideSearchButton={true}
                      onSearch={() => {}}
                    />
                  </TableCell>
                  <TableCell className={classes.columnSeparator}>
                    Rank
                  </TableCell>
                  <TableCell>Score</TableCell>
                  <TableCell>Legacy Score</TableCell>
                  <TableCell className={classes.columnSeparator}>
                    PR AUC
                  </TableCell>
                  <TableCell>Sum Total</TableCell>
                  <TableCell>Accuracy Probability</TableCell>
                  <TableCell className={classes.columnSeparator}>
                    Accuracy
                  </TableCell>
                  <TableCell className={classes.columnSeparator}>
                    Accuracy
                  </TableCell>
                  <TableCell className={classes.columnSeparator}>
                    Avg Score
                  </TableCell>
                  <TableCell className={classes.columnSeparator}>
                    Std Dev
                  </TableCell>
                  <TableCell className={classes.columnSeparator}>
                    ROC AUC
                  </TableCell>
                  <TableCell className={classes.columnSeparator}>
                    Num Std Dev
                  </TableCell>
                  <TableCell className={classes.columnSeparator}>
                    Sensitivity
                  </TableCell>
                  <TableCell className={classes.columnSeparator}>
                    Margin Spread
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedAnalyses.map(modelConfigurationAnalysis => (
                  <TableRow
                    className={
                      modelConfigurationAnalysis.asWalkedAnalysis
                        ? classes.asWalkedRow
                        : modelConfigurationAnalysis.modelSet
                        ? classes.modelSetRow
                        : ''
                    }
                    hover={modelConfigurationAnalysis.eventCount > 0}
                    key={
                      modelConfigurationAnalysis.modelConfiguration
                        ? 'model-' +
                          modelConfigurationAnalysis.modelConfiguration?.id
                        : modelConfigurationAnalysis.modelSet
                        ? 'model-set-' + modelConfigurationAnalysis.modelSet?.id
                        : 'as-walked'
                    }>
                    <TableCell className={classes.modelName}>
                      {modelConfigurationAnalysis.modelConfiguration && (
                        <Link
                          color="inherit"
                          target="_blank"
                          component={RouterLink}
                          to={
                            '/models/model_configurations/' +
                            modelConfigurationAnalysis.modelConfiguration?.id
                          }
                          variant="h6">
                          {
                            modelConfigurationAnalysis.modelConfiguration
                              .displayName
                          }
                        </Link>
                      )}
                      {modelConfigurationAnalysis.modelSet && (
                        <Link
                          color="inherit"
                          target="_blank"
                          component={RouterLink}
                          to={
                            '/models/model_sets/' +
                            modelConfigurationAnalysis.modelSet?.id
                          }
                          variant="h6">
                          Model Set: {modelConfigurationAnalysis.modelSet.name}
                        </Link>
                      )}
                      {modelConfigurationAnalysis.asWalkedAnalysis && (
                        <>
                          As Walked{' '}
                          {modelConfigurationAnalysis.asWalkedModelNames && (
                            <Tooltip
                              title={
                                <ul>
                                  {modelConfigurationAnalysis.asWalkedModelNames?.map(
                                    name => (
                                      <li>{name}</li>
                                    )
                                  )}
                                </ul>
                              }>
                              <InfoIcon className={classes.asWalkedInfoIcon} />
                            </Tooltip>
                          )}
                        </>
                      )}
                      {modelConfigurationAnalysis.modelSet && (
                        <InfoIcon
                          onClick={() =>
                            handleShowModelSetModal(
                              modelConfigurationAnalysis.modelSet
                            )
                          }
                          className={classes.modelSetInfoIcon}
                        />
                      )}
                      {!modelAnalysisComparison.calculating &&
                        !modelAnalysisComparison.analysisEventCountsConsistent &&
                        modelAnalysisComparison.modelConfiguration && (
                          <>
                            <div className={classes.modelEventCount}>
                              Model created/updated:{' '}
                              {modelConfigurationAnalysis.calculated &&
                                moment(
                                  modelConfigurationAnalysis.modelConfiguration
                                    .lastUpdatedTime
                                    ? modelConfigurationAnalysis
                                        .modelConfiguration.lastUpdatedTime
                                    : modelConfigurationAnalysis
                                        .modelConfiguration.createdTime
                                ).format('MMM Do YYYY, h:mm a')}
                            </div>
                            <div className={classes.modelEventCount}>
                              {modelConfigurationAnalysis.calculated &&
                                modelConfigurationAnalysis.eventCount +
                                  ' Walk' +
                                  (modelConfigurationAnalysis.eventCount !== 1
                                    ? 's'
                                    : '')}
                              {modelConfigurationAnalysis.calculated &&
                                (modelConfigurationAnalysis.modelConfiguration
                                  .supportedCellModels
                                  ? ', Supported Cells: ' +
                                    modelConfigurationAnalysis.modelConfiguration.supportedCellModels
                                      .map(cm => CELL_MODELS[cm])
                                      .join(', ')
                                  : '')}
                            </div>
                          </>
                        )}
                    </TableCell>
                    {modelConfigurationAnalysis.calculated ? (
                      <>
                        {!modelConfigurationAnalysis.eventCount ? (
                          <TableCell
                            className={clsx(
                              classes.columnSeparator,
                              classes.modelEventCount
                            )}
                            colSpan={14}>
                            Insufficient supported data available
                          </TableCell>
                        ) : (
                          <>
                            <TableCell className={classes.columnSeparator}>
                              {/* rank */}
                              <RankLabel
                                primaryRank={true}
                                rank={modelConfigurationAnalysis.scoreRank}
                                lowest={
                                  modelConfigurationAnalysis.lowestScoreRank
                                }
                              />
                            </TableCell>
                            {!modelConfigurationAnalysis.breakevenWalksAvailable ? (
                              <TableCell
                                className={clsx(
                                  classes.columnSeparator,
                                  classes.modelEventCount
                                )}
                                colSpan={7}>
                                Insufficient gun/no-gun walks for breakeven bias
                                based calculations
                              </TableCell>
                            ) : (
                              <>
                                <TableCell>
                                  {/* score */}
                                  <NumberFormat
                                    percentage={false}
                                    fixedDecimalScale={true}
                                    precision={4}
                                    value={modelConfigurationAnalysis.score}
                                    suffix={''}
                                  />
                                </TableCell>
                                <TableCell>
                                  {/* legacy score */}
                                  <NumberFormat
                                    percentage={false}
                                    fixedDecimalScale={true}
                                    precision={4}
                                    value={
                                      modelConfigurationAnalysis.legacyScore
                                    }
                                    suffix={''}
                                  />
                                </TableCell>
                                <TableCell className={classes.columnSeparator}>
                                  {/* Precision Recall AUC */}
                                  <NumberFormat
                                    percentage={false}
                                    fixedDecimalScale={true}
                                    precision={4}
                                    value={
                                      modelConfigurationAnalysis.precisionRecallAreaUnderCurve
                                    }
                                    suffix={''}
                                  />
                                </TableCell>
                                <TableCell>
                                  {/* Sum Total */}
                                  <NumberFormat
                                    percentage={false}
                                    fixedDecimalScale={true}
                                    precision={4}
                                    value={
                                      modelConfigurationAnalysis.normalizedOptimalAccuracySumBias
                                    }
                                    suffix={''}
                                  />
                                </TableCell>
                                <TableCell>
                                  {/* Breakeven Sensitivity Probability */}
                                  <NumberFormat
                                    percentage={false}
                                    fixedDecimalScale={true}
                                    precision={4}
                                    value={
                                      modelConfigurationAnalysis.breakevenSensitivityProbability
                                    }
                                    suffix={''}
                                  />
                                </TableCell>
                                <TableCell className={classes.columnSeparator}>
                                  {/* objectPresentAccuracy */}
                                  <NumberFormat
                                    percentage={true}
                                    fixedDecimalScale={true}
                                    precision={2}
                                    value={
                                      modelConfigurationAnalysis.objectPresentAccuracy
                                    }
                                    suffix={'%'}
                                  />
                                </TableCell>
                                <TableCell className={classes.columnSeparator}>
                                  {/* noObjectAccuracy */}
                                  <NumberFormat
                                    percentage={true}
                                    fixedDecimalScale={true}
                                    precision={2}
                                    value={
                                      modelConfigurationAnalysis.noObjectAccuracy
                                    }
                                    suffix={'%'}
                                  />
                                </TableCell>
                              </>
                            )}
                            <TableCell className={classes.columnSeparator}>
                              <NumberFormat
                                percentage={true}
                                fixedDecimalScale={true}
                                precision={2}
                                value={modelConfigurationAnalysis.averageScore}
                                suffix={''}
                              />
                            </TableCell>
                            <TableCell className={classes.columnSeparator}>
                              <NumberFormat
                                percentage={true}
                                fixedDecimalScale={true}
                                precision={2}
                                value={
                                  modelConfigurationAnalysis.standardDeviation
                                }
                                suffix={''}
                              />
                            </TableCell>
                            {!modelConfigurationAnalysis.breakevenWalksAvailable ? (
                              <TableCell
                                className={classes.columnSeparator}
                                colSpan={4}
                              />
                            ) : (
                              <>
                                <TableCell className={classes.columnSeparator}>
                                  {/* ROC AUC */}
                                  <NumberFormat
                                    percentage={false}
                                    fixedDecimalScale={true}
                                    precision={4}
                                    value={
                                      modelConfigurationAnalysis.rocAreaUnderCurve
                                    }
                                    suffix={''}
                                  />
                                </TableCell>
                                <TableCell className={classes.columnSeparator}>
                                  {/* objectPresentStandardDeviations */}
                                  <NumberFormat
                                    percentage={false}
                                    fixedDecimalScale={true}
                                    precision={2}
                                    value={
                                      modelConfigurationAnalysis.objectPresentNumStandardDeviations
                                    }
                                    suffix={''}
                                  />
                                </TableCell>
                                <TableCell className={classes.columnSeparator}>
                                  {' '}
                                  {/* standardDeviationBreakevenBias */}
                                  <NumberFormat
                                    percentage={true}
                                    fixedDecimalScale={true}
                                    precision={2}
                                    value={
                                      modelConfigurationAnalysis.standardDeviationBreakevenBias
                                    }
                                    suffix={''}
                                  />
                                </TableCell>
                                <TableCell className={classes.columnSeparator}>
                                  {/* marginSpread */}
                                  <NumberFormat
                                    percentage={true}
                                    fixedDecimalScale={true}
                                    precision={2}
                                    value={
                                      modelConfigurationAnalysis.marginSpread
                                    }
                                    suffix={''}
                                  />
                                  <span className={classes.marginDetail}>
                                    (
                                    <NumberFormat
                                      percentage={true}
                                      fixedDecimalScale={true}
                                      precision={2}
                                      value={
                                        modelConfigurationAnalysis.objectPresentAverageMargin
                                      }
                                      suffix={''}
                                    />
                                    /
                                    <NumberFormat
                                      percentage={true}
                                      fixedDecimalScale={true}
                                      precision={2}
                                      value={
                                        modelConfigurationAnalysis.noObjectAverageMargin
                                      }
                                      suffix={''}
                                    />
                                    )
                                  </span>
                                </TableCell>
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <TableCell
                        className={classes.columnSeparator}
                        colSpan={14}>
                        <CircularProgress size={10} />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                {!loading && sortedAnalyses.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={14}>No data available</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        )}
      </CardContent>
      <ModelConfigurationDetails
        modelConfiguration={detailsModelConfiguration}
        onClose={handleCloseModelDetails}
      />
      <ModelSetModal
        onClose={() => setDetailsModelSet(null)}
        modelSet={detailsModelSet}
      />
    </Card>
  );
};

ModelAnalysisComparison.propTypes = {
  sessionGroup: PropTypes.object,
  setupScenario: PropTypes.object
};

export default ModelAnalysisComparison;
