import * as actionTypes from 'actions';

const initialState = {
  walkCounts: [],
  walkCountsLoading: false,
  venueStateCounts: {},
  venueStateCountsLoading: false,
  truePortSalesStatusCounts: {},
  truePortSalesStatusCountsLoading: false,
  trueScanSalesStatusCounts: {},
  trueScanSalesStatusCountsLoading: false
};

const organizationDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_ORGANIZATION_DASHBOARD_WALK_COUNTS_PENDING: {
      return {
        ...state,
        walkCountsLoading: true
      };
    }

    case actionTypes.FIND_ORGANIZATION_DASHBOARD_WALK_COUNTS_FULFILLED: {
      return {
        ...state,
        walkCounts: action.payload.data.result,
        walkCountsLoading: initialState.walkCountsLoading
      };
    }

    case actionTypes.FIND_ORGANIZATION_DASHBOARD_DEVICE_VENUE_STATE_COUNTS_PENDING: {
      return {
        ...state,
        venueStateCountsLoading: true
      };
    }

    case actionTypes.FIND_ORGANIZATION_DASHBOARD_DEVICE_VENUE_STATE_COUNTS_FULFILLED: {
      return {
        ...state,
        venueStateCounts: action.payload.data.result,
        venueStateCountsLoading: initialState.walkCountsLoading
      };
    }

    case actionTypes.FIND_ORGANIZATION_DASHBOARD_TRUESCAN_SALES_COUNTS_PENDING: {
      return {
        ...state,
        trueScanSalesStatusCountsLoading: true
      };
    }

    case actionTypes.FIND_ORGANIZATION_DASHBOARD_TRUESCAN_SALES_COUNTS_FULFILLED: {
      return {
        ...state,
        trueScanSalesStatusCounts: action.payload.data.result,
        truePortSalesStatusCountsLoading:
          initialState.truePortSalesStatusCountsLoading
      };
    }

    case actionTypes.FIND_ORGANIZATION_DASHBOARD_TRUEPORT_SALES_COUNTS: {
      return {
        ...state,
        truePortSalesStatusCountsLoading: true
      };
    }

    case actionTypes.FIND_ORGANIZATION_DASHBOARD_TRUEPORT_SALES_COUNTS_FULFILLED: {
      return {
        ...state,
        truePortSalesStatusCounts: action.payload.data.result,
        truePortSalesStatusCountsLoading:
          initialState.truePortSalesStatusCountsLoading
      };
    }

    default: {
      return state;
    }
  }
};

export default organizationDashboardReducer;
