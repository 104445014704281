/* Actions */

export const webSocketMessageReceived = message => dispatch =>
  dispatch({
    type: WEB_SOCKET_MESSAGE_RECEIVED,
    payload: JSON.parse(message)
  });

/* Constants */

export const WEB_SOCKET_MESSAGE_RECEIVED = 'WEB_SOCKET_MESSAGE_RECEIVED';
