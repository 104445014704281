import ws from '../utils/ws';

export const eventPaginatedList = (pageNumber, pageSize) => dispatch =>
  dispatch({
    type: EVENTS_PAGINATED_LIST,
    payload: ws.get(`/events/page/${pageNumber}/rows/${pageSize}`)
  });

export const setEventListDetailsEvent = event => dispatch =>
  dispatch({
    type: SET_EVENT_LIST_DETAILS_EVENT,
    payload: event
  });

export const findEventListDetailsEventRawRadarData = eventId => dispatch =>
  dispatch({
    type: FIND_EVENT_LIST_DETAILS_EVENT_RAW_RADAR_DATA,
    payload: ws.get(`/events/${eventId}/raw_radar_data/`)
  });

export const findEventListDetailsSession = sessionId => dispatch =>
  dispatch({
    type: FIND_EVENT_LIST_DETAILS_SESSION,
    payload: ws.get(`/sessions/${sessionId}`)
  });

export const toggleEventSpecialInterest = eventId => dispatch =>
  dispatch({
    type: TOGGLE_EVENT_SPECIAL_INTEREST,
    payload: ws.get(`/events/${eventId}/toggle_special_interest`)
  });

export const clearEventListDetailsEventRawRadarData = () => dispatch =>
  dispatch({
    type: CLEAR_EVENT_LIST_DETAILS_EVENT_RAW_RADAR_DATA,
    payload: {}
  });

export const findEventListDetailsEventS3Files = eventId => dispatch =>
  dispatch({
    type: FIND_EVENT_LIST_DETAILS_EVENT_S3_FILES,
    payload: ws.get('/events/' + eventId + '/s3_files/')
  });

export const findEventListDetailsEventTrainerData = trainerId => dispatch =>
  dispatch({
    type: FIND_EVENT_LIST_DETAILS_EVENT_TRAINER_DATA,
    payload: ws.get(`/xonar_api/v1/trainer-data/${trainerId}`)
  });

export const findEventListDetailsEventTraining = trainerId => dispatch =>
  dispatch({
    type: FIND_EVENT_LIST_DETAILS_EVENT_TRAINING,
    payload: ws.get(`/trainings/${trainerId}`)
  });

export const findFacialRecognitionOverview = (
  entityType,
  entityId
) => dispatch =>
  dispatch({
    type: FIND_FACIAL_RECOGNITION_OVERVIEW,
    payload: ws.get(
      `/events/facial_recognition_overview/?entityType=${entityType}&entityId=${entityId}`
    )
  });

export const setEventListCommentsEvent = event => dispatch =>
  dispatch({
    type: SET_EVENT_LIST_COMMENTS_EVENT,
    payload: event
  });

export const saveEventAnnotatedSubjectAreaContent = (
  eventId,
  subjectAreaContent
) => dispatch =>
  dispatch({
    type: SAVE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT,
    payload: ws.post(
      `/events/${eventId}/annotated_subject_area_contents/save`,
      subjectAreaContent
    )
  });

export const setEventDetailsTab = tab => dispatch =>
  dispatch({
    type: SET_EVENT_DETAILS_TAB,
    payload: tab
  });

export const setEventDetailsModelSearch = search => dispatch =>
  dispatch({
    type: SET_EVENT_DETAILS_MODEL_SEARCH,
    payload: search
  });

export const setEventDetailsWalkDetailsAiPromptValue = value => dispatch =>
  dispatch({
    type: SET_EVENT_DETAILS_WALK_DETAILS_AI_PROMPT_VALUE,
    payload: value
  });

export const resetEventDetailsWalkDetailsAiPrompt = value => dispatch =>
  dispatch({
    type: RESET_EVENT_DETAILS_WALK_DETAILS_AI_PROMPT,
    payload: value
  });

export const setEventDetailsWalkCollectionSearch = search => dispatch =>
  dispatch({
    type: SET_EVENT_DETAILS_WALK_COLLECTION_SEARCH,
    payload: search
  });

export const deleteEventAnnotatedSubjectAreaContent = (
  eventId,
  index
) => dispatch =>
  dispatch({
    type: DELETE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT,
    payload: ws.post(
      `/events/${eventId}/annotated_subject_area_contents/${index}/delete`
    )
  });

export const updateEventValidationStatus = (
  eventId,
  validationStatus
) => dispatch =>
  dispatch({
    type: UPDATE_EVENT_VALIDATION_STATUS,
    payload: ws.post(`/events/${eventId}/validation_status`, {
      validationStatus
    })
  });

export const updateEventAnnotatedParticipantGender = (
  eventId,
  gender
) => dispatch =>
  dispatch({
    type: UPDATE_EVENT_ANNOTATED_PARTICIPANT_GENDER,
    payload: ws.post(`/events/${eventId}/participant_gender`, { gender })
  });

export const updateEventAnnotatedParticipantClothingType = (
  eventId,
  clothingType
) => dispatch =>
  dispatch({
    type: UPDATE_EVENT_ANNOTATED_PARTICIPANT_CLOTHING_TYPE,
    payload: ws.post(`/events/${eventId}/participant_clothing_type`, {
      clothingType
    })
  });

export const updateEventAnnotatedCellWalkSide = (
  eventId,
  walkSide
) => dispatch =>
  dispatch({
    type: UPDATE_EVENT_ANNOTATED_CELL_WALK_SIDE,
    payload: ws.post(`/events/${eventId}/cell_walk_side`, { walkSide })
  });

export const updateEventAnnotatedParticipantAgeGroup = (
  eventId,
  ageGroup
) => dispatch =>
  dispatch({
    type: UPDATE_EVENT_ANNOTATED_PARTICIPANT_AGE_GROUP,
    payload: ws.post(`/events/${eventId}/participant_age_group`, { ageGroup })
  });

export const eventDetailsSubectAreaContentsAdd = setup => dispatch =>
  dispatch({
    type: EVENT_DETAILS_SAC_ADD,
    payload: setup
  });

export const setEventDetailsEvent = event => dispatch =>
  dispatch({
    type: SET_EVENT_DETAILS_EVENT,
    payload: event
  });

export const setEventDetailsEventLoading = loading => dispatch =>
  dispatch({
    type: SET_EVENT_DETAILS_EVENT_LOADING,
    payload: loading
  });

export const setEventDetailsEventId = eventId => dispatch =>
  dispatch({
    type: SET_EVENT_DETAILS_EVENT_ID,
    payload: eventId
  });

export const setEventDetailsPagniationDisabled = paginationDisabled => dispatch =>
  dispatch({
    type: SET_EVENT_DETAILS_PAGINATION_DISABLED,
    payload: paginationDisabled
  });

export const setEventDetailsWalkCollections = walkCollections => dispatch =>
  dispatch({
    type: SET_EVENT_DETAILS_WALK_COLLECTIONS,
    payload: walkCollections
  });

export const setEventDetailsObjectPredictions = objectPredictions => dispatch =>
  dispatch({
    type: SET_EVENT_DETAILS_OBJECT_PREDICTIONS,
    payload: objectPredictions
  });

export const clearEventDetailsEvent = () => dispatch =>
  dispatch({
    type: CLEAR_EVENT_DETAILS_EVENT
  });

export const eventDetailsSubjectAreaContentValueUpdate = (
  field,
  value
) => dispatch =>
  dispatch({
    type: EVENT_DETAILS_SAC_VALUE_UPDATE,
    payload: { field, value }
  });

export const setEventDetailsSubjectAreaContentSaved = saved => dispatch =>
  dispatch({
    type: SET_EVENT_DETAILS_SAC_SAVED,
    payload: saved
  });

export const findEventImageFiles = event => dispatch =>
  dispatch({
    type: FIND_EVENT_IMAGE_FILES,
    payload: ws.get('/events/' + event.id + '/image_files/')
  });

export const clearEventImageFiles = () => dispatch =>
  dispatch({
    type: CLEAR_EVENT_IMAGE_FILES
  });

export const generateWalkRadarDataImages = eventId => dispatch =>
  dispatch({
    type: GENERATE_WALK_RADAR_DATA_IMAGES,
    payload: ws.post('/events/' + eventId + '/generate_radar_data_images')
  });

export const generateWalkOpenAiDetails = (eventId, prompt) => dispatch =>
  dispatch({
    type: GENERATE_OPEN_AI_WALK_DETAILS,
    payload: ws.post('/events/' + eventId + '/generate_open_ai_walk_details', {
      prompt
    })
  });

export const generateFaceInformation = eventId => dispatch =>
  dispatch({
    type: GENERATE_FACE_INFORMATION,
    payload: ws.post('/events/' + eventId + '/generate_face_information')
  });

/* Action Constants */

export const SET_EVENT_LIST_COMMENTS_EVENT = 'SET_EVENT_LIST_COMMENTS_EVENT';

export const FIND_FACIAL_RECOGNITION_OVERVIEW =
  'FIND_FACIAL_RECOGNITION_OVERVIEW';
export const FIND_FACIAL_RECOGNITION_OVERVIEW_PENDING =
  'FIND_FACIAL_RECOGNITION_OVERVIEW_PENDING';
export const FIND_FACIAL_RECOGNITION_OVERVIEW_FULFILLED =
  'FIND_FACIAL_RECOGNITION_OVERVIEW_FULFILLED';

export const EVENTS_PAGINATED_LIST = 'EVENTS_PAGINATED_LIST';
export const EVENTS_PAGINATED_LIST_PENDING = 'EVENTS_PAGINATED_LIST_PENDING';
export const EVENTS_PAGINATED_LIST_FULFILLED =
  'EVENTS_PAGINATED_LIST_FULFILLED';

export const SET_EVENT_LIST_DETAILS_EVENT = 'SET_EVENT_LIST_DETAILS_EVENT';

export const FIND_EVENT_LIST_DETAILS_EVENT_RAW_RADAR_DATA =
  'FIND_EVENT_LIST_DETAILS_EVENT_RAW_RADAR_DATA';
export const FIND_EVENT_LIST_DETAILS_EVENT_RAW_RADAR_DATA_PENDING =
  'FIND_EVENT_LIST_DETAILS_EVENT_RAW_RADAR_DATA_PENDING';
export const FIND_EVENT_LIST_DETAILS_EVENT_RAW_RADAR_DATA_FULFILLED =
  'FIND_EVENT_LIST_DETAILS_EVENT_RAW_RADAR_DATA_FULFILLED';

export const FIND_EVENT_LIST_DETAILS_SESSION =
  'FIND_EVENT_LIST_DETAILS_SESSION';
export const FIND_EVENT_LIST_DETAILS_SESSION_PENDING =
  'FIND_EVENT_LIST_DETAILS_SESSION_PENDING';
export const FIND_EVENT_LIST_DETAILS_SESSION_FULFILLED =
  'FIND_EVENT_LIST_DETAILS_SESSION_FULFILLED';

export const CLEAR_EVENT_LIST_DETAILS_EVENT_RAW_RADAR_DATA =
  'CLEAR_EVENT_LIST_DETAILS_EVENT_RAW_RADAR_DATA';

export const FIND_EVENT_LIST_DETAILS_EVENT_S3_FILES =
  'FIND_EVENT_LIST_DETAILS_EVENT_S3_FILES';
export const FIND_EVENT_LIST_DETAILS_EVENT_S3_FILES_PENDING =
  'FIND_EVENT_LIST_DETAILS_EVENT_S3_FILES_PENDING';
export const FIND_EVENT_LIST_DETAILS_EVENT_S3_FILES_FULFILLED =
  'FIND_EVENT_LIST_DETAILS_EVENT_S3_FILES_FULFILLED';

export const FIND_EVENT_LIST_DETAILS_EVENT_TRAINER_DATA =
  'FIND_EVENT_LIST_DETAILS_EVENT_TRAINER_DATA';
export const FIND_EVENT_LIST_DETAILS_EVENT_TRAINER_DATA_PENDING =
  'FIND_EVENT_LIST_DETAILS_EVENT_TRAINER_DATA_PENDING';
export const FIND_EVENT_LIST_DETAILS_EVENT_TRAINER_DATA_FULFILLED =
  'FIND_EVENT_LIST_DETAILS_EVENT_TRAINER_DATA_FULFILLED';

export const FIND_EVENT_LIST_DETAILS_EVENT_TRAINING =
  'FIND_EVENT_LIST_DETAILS_EVENT_TRAINING';
export const FIND_EVENT_LIST_DETAILS_EVENT_TRAINING_PENDING =
  'FIND_EVENT_LIST_DETAILS_EVENT_TRAINING_PENDING';
export const FIND_EVENT_LIST_DETAILS_EVENT_TRAINING_FULFILLED =
  'FIND_EVENT_LIST_DETAILS_EVENT_TRAINING_FULFILLED';

export const SAVE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT =
  'SAVE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT';
export const SAVE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_PENDING =
  'SAVE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_PENDING';
export const SAVE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_FULFILLED =
  'SAVE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_FULFILLED';

export const DELETE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT =
  'DELETE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT';
export const DELETE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_PENDING =
  'DELETE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_PENDING';
export const DELETE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_FULFILLED =
  'DELETE_EVENT_ANNOTATED_SUBJECT_AREA_CONTENT_FULFILLED';

export const TOGGLE_EVENT_SPECIAL_INTEREST = 'TOGGLE_EVENT_SPECIAL_INTEREST';
export const TOGGLE_EVENT_SPECIAL_INTEREST_PENDING =
  'TOGGLE_EVENT_SPECIAL_INTEREST_PENDING';
export const TOGGLE_EVENT_SPECIAL_INTEREST_FULFILLED =
  'TOGGLE_EVENT_SPECIAL_INTEREST_FULFILLED';

export const SET_EVENT_DETAILS_EVENT = 'SET_EVENT_DETAILS_EVENT';
export const SET_EVENT_DETAILS_EVENT_LOADING =
  'SET_EVENT_DETAILS_EVENT_LOADING';
export const SET_EVENT_DETAILS_EVENT_ID = 'SET_EVENT_DETAILS_EVENT_ID';
export const SET_EVENT_DETAILS_PAGINATION_DISABLED =
  'SET_EVENT_DETAILS_PAGINATION_DISABLED';
export const SET_EVENT_DETAILS_WALK_COLLECTIONS =
  'SET_EVENT_DETAILS_WALK_COLLECTIONS';
export const SET_EVENT_DETAILS_OBJECT_PREDICTIONS =
  'SET_EVENT_DETAILS_OBJECT_PREDICTIONS';
export const CLEAR_EVENT_DETAILS_EVENT = 'CLEAR_EVENT_DETAILS_EVENT';

export const EVENT_DETAILS_SAC_ADD = 'EVENT_DETAILS_SAC_ADD';
export const EVENT_DETAILS_SAC_VALUE_UPDATE = 'EVENT_DETAILS_SAC_VALUE_UPDATE';
export const SET_EVENT_DETAILS_SAC_SAVED = 'SET_EVENT_DETAILS_SAC_SAVED';

export const FIND_EVENT_IMAGE_FILES = 'FIND_EVENT_IMAGE_FILES';
export const FIND_EVENT_IMAGE_FILES_PENDING = 'FIND_EVENT_IMAGE_FILES_PENDING';
export const FIND_EVENT_IMAGE_FILES_FULFILLED =
  'FIND_EVENT_IMAGE_FILES_FULFILLED';

export const CLEAR_EVENT_IMAGE_FILES = 'CLEAR_EVENT_IMAGE_FILES';

export const UPDATE_EVENT_VALIDATION_STATUS = 'UPDATE_EVENT_VALIDATION_STATUS';
export const UPDATE_EVENT_VALIDATION_STATUS_PENDING =
  'UPDATE_EVENT_VALIDATION_STATUS_PENDING';
export const UPDATE_EVENT_VALIDATION_STATUS_FULFILLED =
  'UPDATE_EVENT_VALIDATION_STATUS_FULFILLED';

export const UPDATE_EVENT_ANNOTATED_PARTICIPANT_GENDER =
  'UPDATE_EVENT_ANNOTATED_PARTICIPANT_GENDER';
export const UPDATE_EVENT_ANNOTATED_PARTICIPANT_GENDER_PENDING =
  'UPDATE_EVENT_ANNOTATED_PARTICIPANT_GENDER_PENDING';
export const UPDATE_EVENT_ANNOTATED_PARTICIPANT_GENDER_FULFILLED =
  'UPDATE_EVENT_ANNOTATED_PARTICIPANT_GENDER_FULFILLED';

export const UPDATE_EVENT_ANNOTATED_PARTICIPANT_CLOTHING_TYPE =
  'UPDATE_EVENT_ANNOTATED_PARTICIPANT_CLOTHING_TYPE';
export const UPDATE_EVENT_ANNOTATED_PARTICIPANT_CLOTHING_TYPE_PENDING =
  'UPDATE_EVENT_ANNOTATED_PARTICIPANT_CLOTHING_TYPE_PENDING';
export const UPDATE_EVENT_ANNOTATED_PARTICIPANT_CLOTHING_TYPE_FULFILLED =
  'UPDATE_EVENT_ANNOTATED_PARTICIPANT_CLOTHING_TYPE_FULFILLED';

export const UPDATE_EVENT_ANNOTATED_CELL_WALK_SIDE =
  'UPDATE_EVENT_ANNOTATED_CELL_WALK_SIDE';
export const UPDATE_EVENT_ANNOTATED_CELL_WALK_SIDE_PENDING =
  'UPDATE_EVENT_ANNOTATED_CELL_WALK_SIDE_PENDING';
export const UPDATE_EVENT_ANNOTATED_CELL_WALK_SIDE_FULFILLED =
  'UPDATE_EVENT_ANNOTATED_CELL_WALK_SIDE_FULFILLED';

export const UPDATE_EVENT_ANNOTATED_PARTICIPANT_AGE_GROUP =
  'UPDATE_EVENT_ANNOTATED_PARTICIPANT_AGE_GROUP';
export const UPDATE_EVENT_ANNOTATED_PARTICIPANT_AGE_GROUP_PENDING =
  'UPDATE_EVENT_ANNOTATED_PARTICIPANT_AGE_GROUP_PENDING';
export const UPDATE_EVENT_ANNOTATED_PARTICIPANT_AGE_GROUP_FULFILLED =
  'UPDATE_EVENT_ANNOTATED_PARTICIPANT_AGE_GROUP_FULFILLED';

export const SET_EVENT_DETAILS_TAB = 'SET_EVENT_DETAILS_TAB';
export const SET_EVENT_DETAILS_MODEL_SEARCH = 'SET_EVENT_DETAILS_MODEL_SEARCH';
export const SET_EVENT_DETAILS_WALK_COLLECTION_SEARCH =
  'SET_EVENT_DETAILS_WALK_COLLECTION_SEARCH';

export const GENERATE_WALK_RADAR_DATA_IMAGES =
  'GENERATE_WALK_RADAR_DATA_IMAGES';
export const GENERATE_WALK_RADAR_DATA_IMAGES_PENDING =
  'GENERATE_WALK_RADAR_DATA_IMAGES_PENDING';
export const GENERATE_WALK_RADAR_DATA_IMAGES_FULFILLED =
  'GENERATE_WALK_RADAR_DATA_IMAGES_FULFILLED';

export const GENERATE_OPEN_AI_WALK_DETAILS = 'GENERATE_OPEN_AI_WALK_DETAILS';
export const GENERATE_OPEN_AI_WALK_DETAILS_PENDING =
  'GENERATE_OPEN_AI_WALK_DETAILS_PENDING';
export const GENERATE_OPEN_AI_WALK_DETAILS_FULFILLED =
  'GENERATE_OPEN_AI_WALK_DETAILS_FULFILLED';

export const GENERATE_FACE_INFORMATION = 'GENERATE_FACE_INFORMATION';
export const GENERATE_FACE_INFORMATION_PENDING =
  'GENERATE_FACE_INFORMATION_PENDING';
export const GENERATE_FACE_INFORMATION_FULFILLED =
  'GENERATE_FACE_INFORMATION_FULFILLED';

export const SET_EVENT_DETAILS_WALK_DETAILS_AI_PROMPT_VALUE =
  'SET_EVENT_DETAILS_WALK_DETAILS_AI_PROMPT_VALUE';

export const RESET_EVENT_DETAILS_WALK_DETAILS_AI_PROMPT =
  'RESET_EVENT_DETAILS_WALK_DETAILS_AI_PROMPT';
