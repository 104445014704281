import ws from '../utils/ws';

/* Actions */

export const findOrganizationDashboardWalkCounts = () => dispatch =>
  dispatch({
    type: FIND_ORGANIZATION_DASHBOARD_WALK_COUNTS,
    payload: ws.get(`/walks/counts/`)
  });

export const findOrganizationDashboardDeviceVenueStateCounts = () => dispatch =>
  dispatch({
    type: FIND_ORGANIZATION_DASHBOARD_DEVICE_VENUE_STATE_COUNTS,
    payload: ws.get(`/cells/venue_state_counts/`)
  });

export const findOrganizationDashboardTruePortSalesCounts = () => dispatch =>
  dispatch({
    type: FIND_ORGANIZATION_DASHBOARD_TRUEPORT_SALES_COUNTS,
    payload: ws.get(`/cells/sales_status_counts/?deviceType=TRUEPORT`)
  });

export const findOrganizationDashboardTrueScanSalesCounts = () => dispatch =>
  dispatch({
    type: FIND_ORGANIZATION_DASHBOARD_TRUESCAN_SALES_COUNTS,
    payload: ws.get(`/cells/sales_status_counts/?deviceType=TRUESCAN`)
  });

/* Constants */

export const FIND_ORGANIZATION_DASHBOARD_WALK_COUNTS =
  'FIND_ORGANIZATION_DASHBOARD_WALK_COUNTS';
export const FIND_ORGANIZATION_DASHBOARD_WALK_COUNTS_PENDING =
  'FIND_ORGANIZATION_DASHBOARD_WALK_COUNTS_PENDING';
export const FIND_ORGANIZATION_DASHBOARD_WALK_COUNTS_FULFILLED =
  'FIND_ORGANIZATION_DASHBOARD_WALK_COUNTS_FULFILLED';

export const FIND_ORGANIZATION_DASHBOARD_DEVICE_VENUE_STATE_COUNTS =
  'FIND_ORGANIZATION_DASHBOARD_DEVICE_VENUE_STATE_COUNTS';
export const FIND_ORGANIZATION_DASHBOARD_DEVICE_VENUE_STATE_COUNTS_PENDING =
  'FIND_ORGANIZATION_DASHBOARD_DEVICE_VENUE_STATE_COUNTS_PENDING';
export const FIND_ORGANIZATION_DASHBOARD_DEVICE_VENUE_STATE_COUNTS_FULFILLED =
  'FIND_ORGANIZATION_DASHBOARD_DEVICE_VENUE_STATE_COUNTS_FULFILLED';

export const FIND_ORGANIZATION_DASHBOARD_TRUESCAN_SALES_COUNTS =
  'FIND_ORGANIZATION_DASHBOARD_TRUESCAN_SALES_COUNTS';
export const FIND_ORGANIZATION_DASHBOARD_TRUESCAN_SALES_COUNTS_PENDING =
  'FIND_ORGANIZATION_DASHBOARD_TRUESCAN_SALES_COUNTS_PENDING';
export const FIND_ORGANIZATION_DASHBOARD_TRUESCAN_SALES_COUNTS_FULFILLED =
  'FIND_ORGANIZATION_DASHBOARD_TRUESCAN_SALES_COUNTS_FULFILLED';

export const FIND_ORGANIZATION_DASHBOARD_TRUEPORT_SALES_COUNTS =
  'FIND_ORGANIZATION_DASHBOARD_TRUEPORT_SALES_COUNTS';
export const FIND_ORGANIZATION_DASHBOARD_TRUEPORT_SALES_COUNTS_PENDING =
  'FIND_ORGANIZATION_DASHBOARD_TRUEPORT_SALES_COUNTS_PENDING';
export const FIND_ORGANIZATION_DASHBOARD_TRUEPORT_SALES_COUNTS_FULFILLED =
  'FIND_ORGANIZATION_DASHBOARD_TRUEPORT_SALES_COUNTS_FULFILLED';
