import * as actionTypes from 'actions';
import { ENTITY_TYPES } from 'common/constants';

const initialState = {
  saved: false,
  savedKnownCarrier: {},
  deleted: false,
  deletedKnownCarrier: {},
  knownCarrier: {
    creationId: null,
    name: '',
    shortCode: '',
    phoneNumber: '',
    emailAddress: '',
    address: '',
    capacity: '',
    type: null,
    description: '',
    latitude: '',
    longitude: '',
    cellHealthRadarThreshold: '',
    cellHealthMagnetometerThreshold: ''
  },
  entityPhotos: [],
  customers: [],
  venues: [],
  validationMessages: {},
  processing: false
};

const knownCarrierCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_KNOWN_CARRIER_PENDING: {
      return {
        ...state,
        processing: true
      };
    }

    case actionTypes.SAVE_KNOWN_CARRIER_FULFILLED: {
      return {
        ...state,
        saved: action.payload.data.success,
        savedKnownCarrier: action.payload.data.result,
        validationMessages: action.payload.data.validationMessages,
        processing: false
      };
    }

    case actionTypes.DELETE_KNOWN_CARRIER_PENDING: {
      return {
        ...state,
        processing: true
      };
    }

    case actionTypes.DELETE_KNOWN_CARRIER_FULFILLED: {
      return {
        ...state,
        deleted: action.payload.data.success,
        deletedKnownCarrier: action.payload.data.result,
        processing: false
      };
    }

    case actionTypes.EDIT_KNOWN_CARRIER_FULFILLED: {
      return {
        ...state,
        knownCarrier: {
          ...action.payload.data.result,
          identifiersCreated: true
        }
      };
    }

    case actionTypes.CREATE_KNOWN_CARRIER_VALUE_RESET: {
      return {
        ...initialState
      };
    }

    case actionTypes.CREATE_KNOWN_CARRIER_VALUE_UPDATE: {
      return {
        ...state,
        knownCarrier: {
          ...state.knownCarrier,
          ...action.payload
        }
      };
    }

    case actionTypes.FIND_CUSTOMERS_PENDING: {
      return {
        ...state,
        customers: initialState.customers
      };
    }

    case actionTypes.FIND_CUSTOMERS_FULFILLED: {
      return {
        ...state,
        customers: action.payload.data
      };
    }

    case actionTypes.FIND_VENUES_PENDING: {
      return {
        ...state,
        venues: initialState.venues
      };
    }

    case actionTypes.FIND_VENUES_FULFILLED: {
      return {
        ...state,
        venues: action.payload.data.result
      };
    }

    case actionTypes.CREATE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.KNOWN_CARRIER &&
        (entityPhoto.entityId === state.knownCarrier.id ||
          entityPhoto.entityTempId === state.knownCarrier.creationId)
      ) {
        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.push(entityPhoto);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.DELETE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.KNOWN_CARRIER &&
        (entityPhoto.entityId === state.knownCarrier.id ||
          entityPhoto.entityTempId === state.knownCarrier.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos.splice(photoIndex, 1);

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.SAVE_ENTITY_PHOTO_FULFILLED: {
      const entityPhoto = action.payload.data.result;
      let result;

      if (
        entityPhoto.entityType === ENTITY_TYPES.KNOWN_CARRIER &&
        (entityPhoto.entityId === state.knownCarrier.id ||
          entityPhoto.entityTempId === state.knownCarrier.creationId)
      ) {
        const photoIndex = state.entityPhotos
          .map(photo => photo.id)
          .indexOf(entityPhoto.id);

        const entityPhotos = state.entityPhotos.slice();
        entityPhotos[photoIndex] = action.payload.data.result;

        return {
          ...state,
          entityPhotos: entityPhotos
        };
      } else {
        result = state;
      }
      return result;
    }

    case actionTypes.FIND_ENTITY_PHOTOS_FULFILLED: {
      return {
        ...state,
        knownCarrierLoading: false,
        entityPhotos: action.payload.data.result
      };
    }

    default: {
      return state;
    }
  }
};

export default knownCarrierCreateReducer;
