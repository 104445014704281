import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { colors, Paper, Typography, Tooltip, Box } from '@material-ui/core';
import { EntityPhotoDetails } from 'components';
import ws from 'utils/ws';
import { setDetailsEntityPhoto } from 'actions';
import theme from 'theme';
import CheckIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(theme => ({
  root: {},
  image: {
    width: 218,
    verticalAlign: 'top',
    display: 'inline-block',
    margin: theme.spacing(1),
    '& img': {
      maxWidth: '100%',
      maxHeight: 'auto'
    }
  },
  trainingValidationImage: {
    width: 120,
    verticalAlign: 'top',
    display: 'inline-block',
    margin: theme.spacing(1),
    '& img': {
      maxWidth: '100%',
      maxHeight: 'auto'
    }
  },
  details: {
    padding: theme.spacing(1)
  },
  detailsLink: {
    cursor: 'pointer'
  },
  divider: {
    marginTop: 0,
    marginBottom: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    paddingTop: 0,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  comment: {
    flexGrow: 1,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 1,
    position: 'relative',
    marginTop: theme.spacing(1)
  }
}));

const EntityPhotos = props => {
  const {
    photos,
    editable,
    readOnly,
    className,
    trainingValidation,
    displayRekognitionDetails,
    imageWidth,
    ...rest
  } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  const handlePhotoClick = entityPhoto => {
    dispatch(setDetailsEntityPhoto(entityPhoto, readOnly));
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {photos.map((entityPhoto, i) => (
        <Paper
          key={i}
          style={imageWidth ? { width: imageWidth } : {}}
          className={clsx(
            trainingValidation ? classes.trainingValidationImage : classes.image
          )}>
          <div
            className={classes.detailsLink}
            onClick={() => handlePhotoClick(entityPhoto)}>
            <img
              src={ws.url(
                '/aws/s3/object?bucket=' +
                  entityPhoto.thumbnailBucket +
                  '&objectKey=' +
                  entityPhoto.thumbnailObjectKey
              )}
            />
            <div className={classes.details}>
              <Typography
                className={classes.value}
                component="p"
                variant="overline">
                {moment(entityPhoto.createdTime).format(
                  trainingValidation ? 'MMM Do YYYY' : 'MMM Do YYYY, h:mm a'
                )}
              </Typography>
              {entityPhoto.deviceLocationPlan && (
                <Typography
                  className={classes.value}
                  component="p"
                  variant="overline">
                  Device Location Plan
                </Typography>
              )}
              {!trainingValidation && entityPhoto.comment && (
                <div className={classes.comment}>
                  <Typography className={classes.value} component="p">
                    {entityPhoto.comment}
                  </Typography>
                </div>
              )}
              {displayRekognitionDetails && (
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    style={{ marginTop: theme.spacing(1) }}
                    className={classes.value}
                    component="p"
                    variant="overline">
                    Rekognition
                  </Typography>
                  {entityPhoto.rekognitionFaceId ? (
                    <Tooltip title={entityPhoto.rekognitionFaceId}>
                      <CheckIcon
                        style={{
                          cursor: 'help',
                          marginBottom: -6,
                          marginLeft: 4,
                          width: 16,
                          color: colors.green[600]
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={entityPhoto.rekognitionErrorMessage}>
                      <ErrorIcon
                        style={{
                          cursor: 'help',
                          marginBottom: -6,
                          marginLeft: 4,
                          width: 16,
                          color: colors.red[600]
                        }}
                      />
                    </Tooltip>
                  )}
                </Box>
              )}
            </div>
          </div>
        </Paper>
      ))}
      <EntityPhotoDetails editable={editable} />
    </div>
  );
};

EntityPhotos.propTypes = {
  className: PropTypes.string,
  photos: PropTypes.array.isRequired
};

export default EntityPhotos;
