import React, { useEffect } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Modal,
  Card,
  CardContent,
  Button,
  ButtonGroup,
  CardHeader,
  Divider,
  LinearProgress,
  Grid,
  Paper,
  Typography,
  colors,
  IconButton,
  Box,
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import {
  eventValidationPaginatedList,
  closeValidateEvents,
  validateEventsUpdateEventValidationStatus,
  eventValidationStatusSummary,
  updateEventAnnotatedParticipantGender,
  updateEventAnnotatedParticipantClothingType,
  toggleEventSpecialInterest,
  findObjectTypes,
  saveEventAnnotatedSubjectAreaContent,
  deleteEventAnnotatedSubjectAreaContent,
  setValidateEventsAddSubjectAreaContentOpen,
  validateEventsSubjectAreaContentValueUpdate,
  setValidateEventsCreateObjectTypeOpen,
  setValidateEventsSelectedEvent,
  findEventValidationImageFiles,
  setValidateEventsSelectedCell,
  setValidateEventsSelectedValidationStatus,
  eventValidationCellList,
  setConfirmMarkNoObjectVerifiedOpen,
  validateEventsMarkNoObjectVerified,
  setValidateEventsPageNumber
} from 'actions';
import { NEW_ITEM_TRIGGER, EVENT_VALIDATION_STATUSES } from 'common/constants';
import ws from '../../utils/ws';
import {
  NumberFormat,
  AddSubjectAreaContent,
  EventVideo,
  CreateObjectType,
  SelectCell,
  EventValidationStatus,
  Paginate,
  MagnetometerResult,
  ConfirmAction,
  ProcessingModal,
  SubjectAreaContents,
  EventPredictionResult
} from 'components';
import FavoriteIcon from '@material-ui/icons/Favorite';
import VideoIcon from '@material-ui/icons/Videocam';
import CameraIcon from '@material-ui/icons/CameraAlt';
import { setValidateEventsDisplayVideo } from 'actions/validateEventsActions';
import { sub } from 'date-fns';
import theme from 'theme';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1220,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  summary: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  status: {
    width: '100%',
    padding: theme.spacing(1.5)
  },
  statusLabel: {
    marginRight: theme.spacing(1.5),
    marginTop: 4
  },
  statusValue: {
    fontWeight: 'bold'
  },
  imageContainer: {
    position: 'relative',
    height: 340
  },
  objectPredictions: {
    display: 'flex',
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1)
  },
  eventTime: {
    display: 'flex',
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    background: 'rgba(20,20,20,0.5)',
    padding: theme.spacing(1),
    borderRadius: 4,
    color: 'white'
  },
  displayModeButton: {},
  objectPrediction: {
    background: 'rgba(20,20,20,0.7)',
    padding: theme.spacing(1),
    borderRadius: 4,
    marginRight: theme.spacing(1),
    paddingTop: 4,
    paddingBottom: 4
  },
  objectPredictionCorrect: {
    background: 'rgba(67,160,71,0.6)'
  },
  objectPredictionIncorrect: {
    background: 'rgba(230,58,53,0.6)'
  },
  objectPredictionValue: {
    fontSize: '1.1em',
    color: 'white'
  },
  verificationActions: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 0,
    padding: theme.spacing(2),
    '& .MuiButton-root': {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5)
    },
    marginBottom: -theme.spacing(3)
  },
  scoreContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  attributeSelection: {
    // marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '& .MuiSvgIcon-root': {
      width: '0.8em',
      height: '0.8em',
      marginLeft: theme.spacing(1)
    },
    '& .MuiButton-root': {
      fontSize: '0.7em',
      height: 28,
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  selectedButton: {
    backgroundColor: colors.blueGrey[50],
    boxShadow: 'inset 0 0 4px #B0BEC5'
  },
  subjectArea: {
    '& .MuiCardHeader-root': {
      paddingLeft: theme.spacing(1.5),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    '& .MuiCardHeader-title': {
      fontSize: 14
    }
  },
  dropZone: {
    borderRadius: 4,
    margin: theme.spacing(1),
    minHeight: 26,
    display: 'flex',
    justifyContent: 'center',
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: 'grey',
    backgroundImage: 'url("../../../images/icon_plus.png")',
    backgroundSize: '15px 15px',
    backgroundPositionX: 'center',
    backgroundPositionY: '4px',
    backgroundRepeat: 'no-repeat'
  },
  activeDropZone: {
    borderWidth: 2,
    backgroundPositionY: '3px'
  },
  draggableObjectType: {
    background: '#F4F6F8',
    display: 'flex',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    cursor: 'grab',
    '& svg': {
      color: colors.grey[400],
      height: '0.8em'
    }
  },
  objectTypesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    paddingBottom: 0
  },
  objectType: {
    display: 'flex',
    background: '#F4F6F8',
    margin: theme.spacing(1),
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    justifyContent: 'space-between'
  },
  removeButton: {
    color: colors.grey[500],
    width: 15,
    height: 15,
    cursor: 'pointer',
    marginTop: 3,
    marginRight: 2
  },
  actionDivider: {
    marginTop: theme.spacing(2),
    backgroundColor: colors.grey[300]
  },
  sectionDivider: {
    marginTop: 8,
    marginBottom: 8
  },
  contentContainer: {
    marginLeft: 16,
    marginRight: 16
  },
  subjectAreaContentsContainer: {},
  subjectAreaContentActions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  sectionButton: {
    marginTop: 16,
    marginBottom: -16
  },
  backButton: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },

  loadMoreButton: {
    marginTop: theme.spacing(2)
  },
  eventListContainer: {
    padding: theme.spacing(2)
  },
  selectedEvent: {
    boxShadow: theme.shadows[5]
  },
  sectionContainer: {
    padding: theme.spacing(2)
  },
  formControl: {
    margin: 0,
    marginBottom: theme.spacing(2)
  },
  rowCount: {
    marginTop: theme.spacing(2)
  },
  subjectAreaContentSelectionContent: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1) + 'px!important'
  },
  scoreTable: {
    width: 1040,
    '& .MuiTableCell-sizeSmall': {
      padding: '6px 6px 6px 10px'
    },
    '& svg': {
      marginBottom: -4,
      marginLeft: 12
    }
  },
  otherObjectTypeSelect: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  addContentButton: {
    marginTop: theme.spacing(2)
  }
}));

const ValidateEvents = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    open,
    pageNumber,
    pageSize,
    rowCount,
    cell,
    validationStatus,
    entityType,
    entityId,
    events,
    selectedEvent,
    validationSummary,
    processing,
    objectTypes,
    addSubjectAreaContentOpen,
    newSubjectAreaContent,
    displayVideo,
    createObjectTypeOpen,
    imageFiles,
    eventCells,
    confirmMarkNoObjectVerifiedOpen,
    markNoObjectDetectedVerifiedProcessing
  } = useSelector(state => state.validateEvents);

  const localTimeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    let mounted = true;

    if (mounted && entityType && entityId) {
      dispatch(findObjectTypes());
      dispatch(
        eventValidationPaginatedList(
          entityType,
          entityId,
          pageNumber,
          pageSize,
          cell,
          validationStatus
        )
      );
      dispatch(eventValidationStatusSummary(entityType, entityId));
      dispatch(eventValidationCellList(entityType, entityId));
    }

    return () => {
      mounted = false;
    };
  }, [entityType, entityId]);

  useEffect(() => {
    let mounted = true;

    if (mounted && selectedEvent?.id) {
      dispatch(findEventValidationImageFiles(selectedEvent));
    }

    return () => {
      mounted = false;
    };
  }, [selectedEvent?.id]);

  useEffect(() => {
    let mounted = true;

    if (mounted && entityType && entityId && cell?.id) {
      dispatch(
        eventValidationPaginatedList(
          entityType,
          entityId,
          pageNumber,
          pageSize,
          cell,
          validationStatus
        )
      );
    }

    return () => {
      mounted = false;
    };
  }, [cell?.id]);

  useEffect(() => {
    let mounted = true;

    if (mounted && entityType && entityId && validationStatus) {
      dispatch(
        eventValidationPaginatedList(
          entityType,
          entityId,
          pageNumber,
          pageSize,
          cell,
          validationStatus
        )
      );
    }

    return () => {
      mounted = false;
    };
  }, [validationStatus, markNoObjectDetectedVerifiedProcessing]);

  if (!open) {
    return null;
  }

  /* Functions */

  const handleClose = () => {
    dispatch(closeValidateEvents());
  };

  const handleSetValidationStatus = (event, validationStatus) => {
    Promise.resolve(
      dispatch(
        validateEventsUpdateEventValidationStatus(event.id, validationStatus)
      )
    ).then(() => {
      dispatch(eventValidationStatusSummary(entityType, entityId));
    });
  };

  const handleUpdateEventParticipantGender = (event, gender) => {
    dispatch(
      updateEventAnnotatedParticipantGender(
        event.id,
        event.annotatedParticipantGender === gender ? null : gender
      )
    );
  };

  const handleUpdateEventParticipantClothingType = (event, clothingType) => {
    dispatch(
      updateEventAnnotatedParticipantClothingType(
        event.id,
        event.annotatedParticipantClothingType === clothingType
          ? null
          : clothingType
      )
    );
  };

  const handleToggleEventSpecialInterest = event => {
    dispatch(toggleEventSpecialInterest(event.id));
  };

  const findAnnotatedSubjectAreaContent = (event, objectType, subjectArea) => {
    let index = null;
    let subjectAreaContent = null;

    if (event.subjectAreaContents) {
      for (let i = 0; i < event.subjectAreaContents.length; i++) {
        const sac = event.subjectAreaContents[i];
        if (
          sac.subjectArea === subjectArea &&
          sac.objectType.name === objectType.name
        ) {
          index = i;
          subjectAreaContent = sac;
        }
      }
    }

    return index !== null
      ? {
          ...subjectAreaContent,
          index
        }
      : undefined;
  };

  const handleSubjectAreaContentFieldChange = (field, value) => {
    if (value === NEW_ITEM_TRIGGER) {
      if (field === 'objectType') {
        dispatch(setValidateEventsCreateObjectTypeOpen(true));
      }
    } else {
      dispatch(validateEventsSubjectAreaContentValueUpdate(field, value));
    }
  };

  const handleSelectNextEvent = () => {
    const index = events.map(event => event.id).indexOf(selectedEvent.id);

    // console.log('===========================================');
    // console.log('index', index);
    // console.log('pageSize', pageSize);
    // console.log('events.length', events.length);
    // console.log('/==========================================');

    if (index + 1 >= pageSize) {
      dispatch(
        eventValidationPaginatedList(
          entityType,
          entityId,
          pageNumber + 1,
          pageSize,
          cell,
          validationStatus
        )
      );
    } else {
      if (events.length > index + 1) {
        dispatch(setValidateEventsSelectedEvent(events[index + 1]));
      }
    }
  };

  const handleSelectPreviousEvent = () => {
    const index = events.map(event => event.id).indexOf(selectedEvent.id);

    if (index - 1 < 0 && pageNumber > 1) {
      dispatch(
        eventValidationPaginatedList(
          entityType,
          entityId,
          pageNumber - 1,
          pageSize,
          cell,
          validationStatus,
          true
        )
      );
    } else {
      if (index - 1 >= 0) {
        dispatch(setValidateEventsSelectedEvent(events[index - 1]));
      }
    }
  };

  const handleSelectEvent = event => {
    dispatch(setValidateEventsSelectedEvent(event));
  };

  const handleConfirmMarkNoObjectVerified = () => {
    dispatch(validateEventsMarkNoObjectVerified(entityType, entityId));
  };

  const handleKeyUp = event => {
    event.preventDefault();

    if (event.which === 86) {
      // V key
      if (
        selectedEvent.annotatedParticipantGender &&
        selectedEvent.annotatedParticipantClothingType
      ) {
        handleSetValidationStatus(selectedEvent, 'VERIFIED');
      }
    } else if (event.which === 69) {
      // S key
      handleSetValidationStatus(selectedEvent, 'SUSPECT');
    } else if (event.which === 80) {
      // P key
      handleSetValidationStatus(selectedEvent, 'PENDING');
    } else if (event.which === 77) {
      // M key
      handleUpdateEventParticipantGender(selectedEvent, 'MALE');
    } else if (event.which === 70) {
      // F key
      handleUpdateEventParticipantGender(selectedEvent, 'FEMALE');
    } else if (event.which === 39) {
      // Right arrow key
      handleSelectNextEvent(selectedEvent);
    } else if (event.which === 37) {
      // Left arrow key
      handleSelectPreviousEvent(selectedEvent);
    } else if (event.which === 97 || event.which === 49) {
      // Left arrow key
      handleUpdateEventParticipantClothingType(selectedEvent, 'LIGHT');
    } else if (event.which === 98 || event.which === 50) {
      // Left arrow key
      handleUpdateEventParticipantClothingType(selectedEvent, 'MEDIUM');
    } else if (event.which === 99 || event.which === 51) {
      // Left arrow key
      handleUpdateEventParticipantClothingType(selectedEvent, 'HEAVY');
    }
  };

  const handleSelectCell = cell => {
    dispatch(setValidateEventsSelectedCell(cell));
  };

  const handleSelectValidationStatus = status => {
    dispatch(setValidateEventsPageNumber(1));
    dispatch(setValidateEventsSelectedValidationStatus(status));
  };

  const handleEventListPageChange = ({ selected }) => {
    dispatch(
      eventValidationPaginatedList(
        entityType,
        entityId,
        selected + 1,
        pageSize,
        cell,
        validationStatus
      )
    );
  };

  const handleDeleteSubjectAreaContent = subjectAreaContentIndex => {
    dispatch(
      deleteEventAnnotatedSubjectAreaContent(
        selectedEvent.id,
        subjectAreaContentIndex
      )
    );
  };

  /* /End Functions */

  /* Components */

  const ValidationStatusSelect = props => {
    const { className, fullWidth, status } = props;

    return (
      <TextField
        className={className}
        margin="dense"
        fullWidth={fullWidth}
        label="Status"
        name="status"
        onChange={e =>
          handleSelectValidationStatus(
            e.target.value === '' ? null : e.target.value
          )
        }
        select
        SelectProps={{ native: true }}
        value={status ? status : 'all'}
        variant="outlined">
        <option value="all">All</option>
        {Object.keys(EVENT_VALIDATION_STATUSES).map(status => (
          <option key={status} value={status}>
            {EVENT_VALIDATION_STATUSES[status]}
          </option>
        ))}
      </TextField>
    );
  };

  const SelectDisplayMode = () => {
    return (
      <IconButton
        style={{
          position: 'absolute',
          top: 5,
          zIndex: 100,
          backgroundColor: 'white',
          right: 5
        }}
        size="small"
        className={classes.displayModeButton}
        color="primary"
        key="close"
        onClick={() => dispatch(setValidateEventsDisplayVideo(!displayVideo))}>
        {displayVideo ? <CameraIcon /> : <VideoIcon />}
      </IconButton>
    );
  };

  const SelectGender = props => {
    const { event } = props;

    return (
      <ButtonGroup fullWidth className={classes.attributeSelection}>
        <Button
          fullWidth
          onClick={() => handleUpdateEventParticipantGender(event, 'MALE')}
          variant="outlined"
          className={
            event.annotatedParticipantGender === 'MALE'
              ? classes.selectedButton
              : ''
          }>
          Male (m)
        </Button>
        <Button
          fullWidth
          onClick={() => handleUpdateEventParticipantGender(event, 'FEMALE')}
          variant="outlined"
          className={
            event.annotatedParticipantGender === 'FEMALE'
              ? classes.selectedButton
              : ''
          }>
          Female (F)
        </Button>
      </ButtonGroup>
    );
  };

  const SelectClothingType = props => {
    const { event } = props;

    return (
      <ButtonGroup fullWidth className={classes.attributeSelection}>
        <Button
          onClick={() =>
            handleUpdateEventParticipantClothingType(event, 'LIGHT')
          }
          variant="outlined"
          className={
            event.annotatedParticipantClothingType === 'LIGHT'
              ? classes.selectedButton
              : ''
          }>
          Light (1)
        </Button>
        <Button
          onClick={() =>
            handleUpdateEventParticipantClothingType(event, 'MEDIUM')
          }
          variant="outlined"
          className={
            event.annotatedParticipantClothingType === 'MEDIUM'
              ? classes.selectedButton
              : ''
          }>
          Medium (2)
        </Button>
        <Button
          onClick={() =>
            handleUpdateEventParticipantClothingType(event, 'HEAVY')
          }
          variant="outlined"
          className={
            event.annotatedParticipantClothingType === 'HEAVY'
              ? classes.selectedButton
              : ''
          }>
          Heavy (3)
        </Button>
      </ButtonGroup>
    );
  };

  const SelectValidationStatus = props => {
    const { event } = props;

    const eventValid =
      event.annotatedParticipantGender &&
      event.annotatedParticipantClothingType;

    return (
      <ButtonGroup fullWidth className={classes.attributeSelection}>
        <Button
          onClick={() => handleSetValidationStatus(event, 'PENDING')}
          variant="outlined"
          className={
            event.validationStatus === 'PENDING' ? classes.selectedButton : ''
          }>
          Pending (p)
        </Button>
        <Button
          onClick={() => handleSetValidationStatus(event, 'SUSPECT')}
          variant="outlined"
          className={
            event.validationStatus === 'SUSPECT' ? classes.selectedButton : ''
          }>
          Excluded (e)
        </Button>
        <Button
          disabled={!eventValid}
          onClick={() => handleSetValidationStatus(event, 'VERIFIED')}
          variant="outlined"
          className={
            event.validationStatus === 'VERIFIED' ? classes.selectedButton : ''
          }>
          Verified (v)
        </Button>
      </ButtonGroup>
    );
  };

  const SelectSpecialInterest = props => {
    const { event } = props;

    return (
      <ButtonGroup fullWidth className={classes.attributeSelection}>
        <Button
          disabled={processing}
          onClick={() => handleToggleEventSpecialInterest(event)}
          variant="outlined"
          className={event.specialInterest ? classes.selectedButton : ''}>
          {/* <FavoriteIcon style={{ color: colors.purple[600], margin: 0 }} /> */}
          Special Interest
        </Button>
      </ButtonGroup>
    );
  };

  const ValidationSummaryCount = props => {
    const { status, count } = props;

    const statusColors = {
      [EVENT_VALIDATION_STATUSES.PENDING]: colors.blueGrey[600],
      [EVENT_VALIDATION_STATUSES.VERIFIED]: colors.green[600],
      [EVENT_VALIDATION_STATUSES.SUSPECT]: colors.red[600]
    };

    return (
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography
          color="inherit"
          component="h6"
          variant="overline"
          className={classes.statusLabel}>
          {status}
        </Typography>
        <Typography
          style={{ color: statusColors[status] }}
          component="h6"
          className={classes.statusValue}>
          <NumberFormat value={count !== null ? count : 0}></NumberFormat>
        </Typography>
      </Box>
    );
  };

  /* /End Components */

  return (
    <>
      <Modal onClose={handleClose} onKeyUp={handleKeyUp} open={open}>
        <Card className={classes.root}>
          <CardHeader title={`Validate Walks`} />

          <Divider />

          <CardContent className={classes.content} style={{ paddingBottom: 0 }}>
            {processing && <LinearProgress className={classes.processing} />}

            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                style={{
                  width: 400,
                  borderRight: '1px solid #EEEEEE',
                  paddingRight: theme.spacing(2)
                }}>
                <Box
                  className={classes.sectionContainer}
                  style={{ paddingRight: 0 }}>
                  <Paper
                    style={{
                      marginTop: 8,
                      marginBottom: 24
                    }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <ValidationSummaryCount
                          status={EVENT_VALIDATION_STATUSES.PENDING}
                          count={validationSummary.pendingEvents}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <ValidationSummaryCount
                          status={EVENT_VALIDATION_STATUSES.SUSPECT}
                          count={validationSummary.suspectEvents}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <ValidationSummaryCount
                          status={EVENT_VALIDATION_STATUSES.VERIFIED}
                          count={validationSummary.verifiedEvents}
                        />
                      </Grid>
                    </Grid>
                  </Paper>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <SelectCell
                        margin="dense"
                        fullWidth
                        className={classes.formControl}
                        onChange={handleSelectCell}
                        cell={cell}
                        filteredCells={eventCells}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ValidationStatusSelect
                        fullWidth
                        status={validationStatus}
                        className={classes.formControl}
                      />
                    </Grid>
                  </Grid>
                  <Paper className={classes.eventListContainer}>
                    <Grid container spacing={2}>
                      {events.map((event, i) => (
                        <Grid item md={12} lg={6} key={i}>
                          <Paper
                            key={event.id}
                            style={{
                              padding: 10,
                              cursor: 'pointer',
                              position: 'relative'
                            }}
                            onClick={() => handleSelectEvent(event)}
                            className={
                              selectedEvent?.id === event.id
                                ? classes.selectedEvent
                                : ''
                            }>
                            {selectedEvent?.id === event.id && (
                              <Box
                                style={{
                                  position: 'absolute',
                                  left: 10,
                                  top: 10,
                                  width: 20,
                                  height: 20,
                                  background:
                                    'linear-gradient(to top left, transparent 0%, transparent 50%, #1E88E5 50%, #1E88E5 100%)'
                                }}
                              />
                            )}
                            <Box
                              style={{
                                position: 'absolute',
                                right: 14,
                                top: 13,
                                boxShadow: theme.shadows[10]
                              }}>
                              <EventValidationStatus event={event} />
                            </Box>

                            <Box
                              style={{
                                width: '100%',
                                backgroundImage: `url(${ws.url(
                                  `/events/${event.id}/image?imageType=ENTRY`
                                )})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                aspectRatio: '2/3',
                                backgroundColor: '#EFEFEF'
                              }}
                            />
                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: 4,
                                marginBottom: -4
                              }}>
                              <Typography color="inherit" component="h6">
                                {event?.cell?.name.substring(7)} /{' '}
                                {event.walkTime &&
                                  moment(event.walkTime).format('h:mm:ss')}
                              </Typography>
                            </Box>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                    <Box className={classes.paginationContainer}>
                      <Paginate
                        pageRangeDisplayed={3}
                        pageCount={Math.ceil(rowCount / pageSize)}
                        onPageChange={handleEventListPageChange}
                        forcePage={pageNumber - 1}
                      />
                    </Box>
                  </Paper>
                </Box>
              </Box>
              <Box
                style={{
                  padding: theme.spacing(2),
                  width: 820,
                  boxShadow: '0px 0px 10px 0px lightgray inset',
                  backgroundColor: '#fafafa'
                }}>
                {selectedEvent && (
                  <>
                    <Paper
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginBottom: theme.spacing(2)
                      }}>
                      <Table size="small" style={{ width: 408 }}>
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ width: 100 }}>Time</TableCell>
                            <TableCell style={{ width: 300 }}>
                              {moment(selectedEvent.walkTime)
                                .tz(selectedEvent.timeZoneId || localTimeZoneId)
                                .format('MMM Do YYYY, h:mm:ss a')}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ width: 100 }}>Cell</TableCell>
                            <TableCell style={{}}>
                              {selectedEvent.cellName}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Walk ID</TableCell>
                            <TableCell>{selectedEvent.id}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Object Detected</TableCell>
                            <TableCell>
                              {selectedEvent.asWalkedObjectDetected === null
                                ? ' - '
                                : selectedEvent.asWalkedObjectDetected
                                ? 'Yes'
                                : 'No'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Torso</TableCell>
                            <TableCell>
                              <span
                                style={{ display: 'inline-block', width: 20 }}>
                                L:{' '}
                              </span>
                              <span
                                style={{ display: 'inline-block', width: 80 }}>
                                <NumberFormat
                                  value={selectedEvent.radarScoreLeft}
                                  percentage
                                  suffix={'%'}
                                />
                              </span>
                              <span
                                style={{ display: 'inline-block', width: 20 }}>
                                R:{' '}
                              </span>
                              <span
                                style={{ display: 'inline-block', width: 80 }}>
                                <NumberFormat
                                  value={selectedEvent.radarScoreRight}
                                  percentage
                                  suffix={'%'}
                                />
                              </span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Ankle</TableCell>
                            <TableCell>
                              <span
                                style={{ display: 'inline-block', width: 20 }}>
                                L:{' '}
                              </span>
                              <span
                                style={{ display: 'inline-block', width: 80 }}>
                                <MagnetometerResult
                                  style={{ marginBottom: -3 }}
                                  score={selectedEvent.magnetometerScoreLeft}
                                />
                              </span>
                              <span
                                style={{ display: 'inline-block', width: 20 }}>
                                R:{' '}
                              </span>
                              <span
                                style={{ display: 'inline-block', width: 80 }}>
                                <MagnetometerResult
                                  style={{ marginBottom: -3 }}
                                  score={selectedEvent.magnetometerScoreRight}
                                />
                              </span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ width: 80 }}>
                              Threshold
                            </TableCell>
                            <TableCell style={{ width: 90 }}>
                              <NumberFormat
                                value={selectedEvent.radarThreshold}
                                percentage
                                suffix={'%'}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Threats</TableCell>
                            <TableCell>
                              {
                                selectedEvent.objectOfInterestLocationDescription
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Mag. Status</TableCell>
                            <TableCell>
                              {selectedEvent.magnetometerDeactivated !== null
                                ? selectedEvent.magnetometerDeactivated
                                  ? 'Disabled'
                                  : 'Active'
                                : ''}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ border: 0 }}>
                              Prediction
                            </TableCell>
                            <TableCell
                              style={{ border: 0 }}
                              className={classes.labelCell}>
                              <EventPredictionResult
                                predictionCorrect={
                                  selectedEvent.predictionCorrect
                                }
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <Box
                        style={{
                          overflow: 'hidden',
                          position: 'relative'
                        }}>
                        <SelectDisplayMode />
                        <img
                          src={ws.url(
                            `/events/${selectedEvent.id}/image?imageType=ENTRY`
                          )}
                          style={{
                            width: '100%',
                            position: 'absolute',
                            top: -620,
                            backgroundColor: '#FAFAFA',
                            opacity: 0.3,
                            filter: 'blur(20px)'
                          }}
                        />
                        <div
                          className={classes.imageContainer}
                          style={{ filter: 'none' }}>
                          <div
                            style={{
                              display: !displayVideo ? 'flex' : 'none',
                              justifyContent: 'center'
                            }}>
                            {imageFiles.map((img, i) => (
                              <ReactImageMagnify
                                {...{
                                  enlargedImagePosition: 'over',
                                  smallImage: {
                                    // isFluidWidth: true,
                                    src: ws.url(
                                      `/aws/s3/object?bucket=${img.bucket}&objectKey=${img.key}`
                                    ),
                                    width: 190,
                                    height: 340
                                  },
                                  largeImage: {
                                    src: ws.url(
                                      `/aws/s3/object?bucket=${img.bucket}&objectKey=${img.key}`
                                    ),
                                    width: 1400,
                                    height: 2500
                                  }
                                }}
                              />
                            ))}
                          </div>
                          <EventVideo
                            width={390}
                            height={340}
                            eventId={selectedEvent.id}
                          />
                        </div>
                      </Box>
                    </Paper>
                    <Paper
                      style={{
                        padding: theme.spacing(2),
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                      }}>
                      <Box style={{ width: 330 }}>
                        <Typography variant="h6">Gender</Typography>
                        <SelectGender event={selectedEvent} />
                        <Typography variant="h6">Clothing</Typography>
                        <SelectClothingType event={selectedEvent} />
                        <Typography variant="h6">Validation</Typography>
                        <SelectValidationStatus event={selectedEvent} />
                        <Typography variant="h6">Other</Typography>
                        <SelectSpecialInterest event={selectedEvent} />
                      </Box>
                      <Box style={{ width: 390 }}>
                        <Typography style={{ marginBottom: 8 }} variant="h6">
                          Threats/Items
                        </Typography>
                        <SubjectAreaContents
                          subjectAreaContents={
                            selectedEvent.annotatedSubjectAreaContents
                          }
                          onDelete={handleDeleteSubjectAreaContent}
                        />
                        <Button
                          className={classes.addContentButton}
                          variant="contained"
                          size="small"
                          onClick={() =>
                            dispatch(
                              setValidateEventsAddSubjectAreaContentOpen(true)
                            )
                          }>
                          Add Item
                        </Button>
                      </Box>
                    </Paper>
                  </>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Modal>

      <AddSubjectAreaContent
        open={addSubjectAreaContentOpen}
        onClose={() =>
          dispatch(setValidateEventsAddSubjectAreaContentOpen(false))
        }
        subjectAreaContent={newSubjectAreaContent}
        hidePockets={true}
        hideModels={true}
        objectTypes={objectTypes}
        onFieldChange={handleSubjectAreaContentFieldChange}
        onSave={() =>
          dispatch(
            saveEventAnnotatedSubjectAreaContent(
              selectedEvent.id,
              newSubjectAreaContent
            )
          )
        }
      />

      <CreateObjectType
        open={createObjectTypeOpen}
        onClose={() => dispatch(setValidateEventsCreateObjectTypeOpen(false))}
      />

      <ConfirmAction
        message={
          'Please confirm that you wish to mark all no-object detected walks as verified.'
        }
        onClose={() => dispatch(setConfirmMarkNoObjectVerifiedOpen(false))}
        onConfirm={handleConfirmMarkNoObjectVerified}
        open={confirmMarkNoObjectVerifiedOpen}
      />

      <ProcessingModal
        message="Verifying events..."
        open={markNoObjectDetectedVerifiedProcessing}
      />
    </>
  );
};

ValidateEvents.displayName = 'ValidateEvents';

export default ValidateEvents;
