import { applyMiddleware, createStore, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import ws from '../utils/ws';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { webSocketMessageReceived } from 'actions';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from 'reducers';

export default function configureStore(preloadedState = {}) {
  const middlewares = [promise, thunk, logger];
  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  const rws = new ReconnectingWebSocket(ws.webSocketUrl());

  rws.addEventListener('open', () => {});

  rws.addEventListener('message', event => {
    store.dispatch(webSocketMessageReceived(event.data));
  });

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
}
