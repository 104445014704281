import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Divider,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import {
  SUBJECT_AREAS,
  POCKET_ITEMS_SUBJECT_AREAS,
  POCKET_LOCATIONS,
  NEW_ITEM_TRIGGER,
  OBJECT_TYPE_CATEGORIES,
  COMMON_SUBJECT_AREAS
} from 'common/constants';
import theme from 'theme';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 350,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  field: {
    marginTop: theme.spacing(2)
  },
  content: {
    paddingTop: theme.spacing(1)
  }
}));

const AddSubjectAreaContent = props => {
  const {
    open,
    onClose,
    subjectAreaContent,
    onFieldChange,
    objectTypes,
    onSave,
    hidePockets,
    className
  } = props;

  const objectTypeCategories = {};
  for (let i = 0; i < objectTypes.length; i++) {
    const objectType = objectTypes[i];
    let types;
    if (objectTypeCategories.hasOwnProperty(objectType.category)) {
      types = objectTypeCategories[objectType.category];
    } else {
      types = [];
      objectTypeCategories[objectType.category] = types;
    }
    types.push(objectType);
  }

  /* Sort object type categories */
  for (let cat in objectTypeCategories) {
    objectTypeCategories[cat].sort((t1, t2) => {
      if (t1.name.toUpperCase() < t2.name.toUpperCase()) {
        return -1;
      }
      if (t1.name.toUpperCase() > t2.name.toUpperCase()) {
        return 1;
      }
      return 0;
    });
  }

  const classes = useStyles();

  if (!open || !subjectAreaContent || subjectAreaContent.saved) {
    return null;
  }

  const commonObjectTypes = objectTypes
    .filter(ot => ot.eventValidationApplicable)
    .sort((t1, t2) => t2.eventValidationPriority - t1.eventValidationPriority);

  return (
    <Modal onClose={onClose} open={open}>
      <Card className={clsx(classes.root, className)}>
        <CardHeader title="Add Item" />
        <Divider />
        <CardContent className={classes.content}>
          <Grid container spacing={0}>
            {COMMON_SUBJECT_AREAS.map(subjectArea => (
              <Grid key={subjectArea} item xs={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={subjectAreaContent.subjectArea === subjectArea}
                      color="primary"
                      onChange={() => {
                        onFieldChange(
                          'subjectArea',
                          subjectAreaContent.subjectArea === subjectArea
                            ? ''
                            : subjectArea
                        );
                      }}
                    />
                  }
                  label={SUBJECT_AREAS[subjectArea]}
                />
              </Grid>
            ))}
          </Grid>

          <TextField
            className={classes.field}
            fullWidth
            label="Area"
            name="subjectArea"
            onChange={event => {
              onFieldChange(event.target.name, event.target.value);
            }}
            select
            // eslint-disable-next-line react/jsx-sort-props
            SelectProps={{ native: true }}
            value={subjectAreaContent.subjectArea}
            variant="outlined">
            <option value="" />

            {Object.keys(SUBJECT_AREAS)
              .filter(subjectArea => subjectArea !== 'BAG')
              .map(objectLocation => (
                <option key={objectLocation} value={objectLocation}>
                  {SUBJECT_AREAS[objectLocation]}
                </option>
              ))}
          </TextField>
          {!hidePockets && (
            <TextField
              className={classes.field}
              fullWidth
              disabled={
                POCKET_ITEMS_SUBJECT_AREAS.indexOf(
                  SUBJECT_AREAS[subjectAreaContent.subjectArea]
                ) < 0
              }
              label="Pocket"
              name="pocketLocation"
              onChange={event =>
                onFieldChange(event.target.name, event.target.value)
              }
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={
                subjectAreaContent.pocketLocation &&
                POCKET_ITEMS_SUBJECT_AREAS.indexOf(
                  SUBJECT_AREAS[subjectAreaContent.subjectArea]
                ) > -1
                  ? subjectAreaContent.pocketLocation
                  : ''
              }
              variant="outlined">
              <option key="" value=""></option>

              {Object.keys(POCKET_LOCATIONS).map(pocketLocation => (
                <option key={pocketLocation} value={pocketLocation}>
                  {POCKET_LOCATIONS[pocketLocation]}
                </option>
              ))}
            </TextField>
          )}

          <Divider
            style={{
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(2)
            }}
          />

          {objectTypes.length > 0 && (
            <>
              <Grid container spacing={0}>
                {commonObjectTypes.map(objectType => (
                  <Grid key={objectType.id} item xs={12} lg={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{ userSelect: 'none' }}
                          checked={
                            subjectAreaContent?.objectType?.id === objectType.id
                          }
                          color="primary"
                          onChange={() =>
                            onFieldChange(
                              'objectType',
                              subjectAreaContent?.objectType?.id ===
                                objectType.id
                                ? null
                                : objectType
                            )
                          }
                        />
                      }
                      label={objectType.name}
                    />
                  </Grid>
                ))}
              </Grid>
              <TextField
                className={classes.field}
                fullWidth
                label="Object"
                name="objectType"
                onChange={event => {
                  let val;
                  if (event.target.value === NEW_ITEM_TRIGGER) {
                    val = event.target.value;
                  } else {
                    val = objectTypes.filter(
                      t => t.id === event.target.value
                    )[0];
                  }
                  val = val === undefined ? null : val;
                  onFieldChange(event.target.name, val);
                }}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={
                  subjectAreaContent.objectType &&
                  subjectAreaContent.objectType.id
                    ? subjectAreaContent.objectType.id
                    : ''
                }
                variant="outlined">
                <option key="" value="" />

                {Object.keys(objectTypeCategories).map(category => (
                  <optgroup
                    key={category}
                    label={OBJECT_TYPE_CATEGORIES[category].name}>
                    {objectTypeCategories[category].map(objectType => (
                      <option key={objectType.id} value={objectType.id}>
                        {objectType.name}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </TextField>
            </>
          )}
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={onClose} variant="contained">
            Cancel
          </Button>
          <Button
            disabled={
              !subjectAreaContent.subjectArea || !subjectAreaContent.objectType
            }
            onClick={onSave}
            variant="contained">
            Add
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

AddSubjectAreaContent.defaultProps = {
  open: false
};

export default AddSubjectAreaContent;
