/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import clsx from 'clsx';
import Configuration from 'config';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Menu,
  IconButton,
  Toolbar,
  Hidden,
  colors,
  Typography,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Paper,
  Button
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { KIOSK_PATHS } from 'common/constants';
import theme from 'theme';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  },
  siteLogo: {
    height: 24,
    display: 'block'
  },
  env_dev: {
    backgroundColor: colors.blueGrey[600]
  },
  env_uat: {
    backgroundColor: colors.green[600]
  },
  env_prod: {
    backgroundColor: '#08102A'
  },
  avatar: {
    borderRadius: 100,
    height: 30,
    width: 30,
    marginRight: theme.spacing(1),
    cursor: 'pointer'
  }
}));

const TopBar = props => {
  const { onOpenNavBarMobile, className, ...rest } = props;
  const { sessionUser } = useSelector(state => state.session);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();

  const environmentCode = Configuration.getConfig().code;

  const path = window.location.pathname + window.location.search;
  const hideSignOut = KIOSK_PATHS.indexOf(path) > -1;

  const handleProfileClick = event => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleLogout = () => {
    window.location = '/auth/logout';
  };

  return (
    <AppBar
      {...rest}
      className={clsx(
        classes.root,
        className,
        classes['env_' + environmentCode]
      )}
      color="primary"
      // style={{
      //   backgroundImage: 'url(/images/holidays.png)',
      //   backgroundRepeat: 'repeat-x',
      //   backgroundSize: 450
      // }}
    >
      <Toolbar>
        <img
          className={classes.siteLogo}
          alt="Logo"
          src={'/images/logos/logo-white.png'}
        />
        <div className={classes.flexGrow} />

        {!hideSignOut && (
          <>
            <img
              className={classes.avatar}
              src={sessionUser.pictureUrl}
              onClick={handleProfileClick}
            />

            <Menu
              style={{
                marginTop: theme.spacing(5),
                textAlign: 'center'
              }}
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={menuOpen}
              onClose={() => setMenuOpen(false)}>
              <Typography
                style={{
                  margin: theme.spacing(2),
                  fontWeight: 'bold'
                }}>
                {sessionUser?.displayName}
              </Typography>
              <Divider />
              <Box style={{ padding: theme.spacing(2) }}>
                <Paper>
                  <Table size="small">
                    <TableBody>
                      {sessionUser.roles
                        .map(role => role.code)
                        .map(role => (
                          <TableRow key={role}>
                            <TableCell>{role}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Box>
              <Divider />
              <Box
                style={{
                  padding: theme.spacing(2),
                  paddingBottom: theme.spacing(1),
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                <Button size="small" onClick={handleLogout} variant="outlined">
                  Sign Out
                </Button>
              </Box>
            </Menu>
          </>
        )}

        <Hidden lgUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
