import ws from '../utils/ws';

/* Actions */

export const findModelAnalysisComparison = (
  walkSourceType,
  walkSourceId
) => dispatch =>
  dispatch({
    type: FIND_MODEL_ANALYSIS_COMPARISON,
    payload: ws.get(
      `/model_analysis_comparisons/${walkSourceType}/${walkSourceId}`
    )
  });

export const findModelAnalysisComparisonSilently = (
  walkSourceType,
  walkSourceId
) => dispatch =>
  dispatch({
    type: FIND_MODEL_ANALYSIS_COMPARISON_SILENTLY,
    payload: ws.get(
      `/model_analysis_comparisons/${walkSourceType}/${walkSourceId}`
    )
  });

export const recalculateModelAnalysisComparison = (
  walkSourceType,
  walkSourceId
) => dispatch =>
  dispatch({
    type: RECALCULATE_MODEL_ANALYSIS_COMPARISON,
    payload: ws.get(
      `/model_analysis_comparisons/${walkSourceType}/${walkSourceId}/recalculate`
    )
  });

/* Constants */

export const FIND_MODEL_ANALYSIS_COMPARISON = 'FIND_MODEL_ANALYSIS_COMPARISON';
export const FIND_MODEL_ANALYSIS_COMPARISON_PENDING =
  'FIND_MODEL_ANALYSIS_COMPARISON_PENDING';
export const FIND_MODEL_ANALYSIS_COMPARISON_FULFILLED =
  'FIND_MODEL_ANALYSIS_COMPARISON_FULFILLED';

export const FIND_MODEL_ANALYSIS_COMPARISON_SILENTLY =
  'FIND_MODEL_ANALYSIS_COMPARISON_SILENTLY';
export const FIND_MODEL_ANALYSIS_COMPARISON_SILENTLY_PENDING =
  'FIND_MODEL_ANALYSIS_COMPARISON_SILENTLY_PENDING';
export const FIND_MODEL_ANALYSIS_COMPARISON_SILENTLY_FULFILLED =
  'FIND_MODEL_ANALYSIS_COMPARISON_SILENTLY_FULFILLED';

export const RECALCULATE_MODEL_ANALYSIS_COMPARISON =
  'RECALCULATE_MODEL_ANALYSIS_COMPARISON';
export const RECALCULATE_MODEL_ANALYSIS_COMPARISON_PENDING =
  'RECALCULATE_MODEL_ANALYSIS_COMPARISON_PENDING';
export const RECALCULATE_MODEL_ANALYSIS_COMPARISON_FULFILLED =
  'RECALCULATE_MODEL_ANALYSIS_COMPARISON_FULFILLED';
