import React from 'react';
import {
  Modal,
  Card,
  CardContent,
  LinearProgress,
  Typography,
  CardHeader,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CrosstalkChart from 'views/CellDetails/components/CrosstalkMeasurements/CrosstalkChart';
import { CROSSTALK_RADAR_GROUPS } from 'common/constants';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1200,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    '& p': {
      textAlign: 'center'
    }
  },
  message: {
    marginBottom: theme.spacing(2)
  }
}));

const CrosstalkMeasurementDetailsModal = props => {
  const classes = useStyles();

  const { onClose, crosstalkMeasurement } = props;

  if (!crosstalkMeasurement) return null;

  return (
    <Modal open={crosstalkMeasurement !== null} onClose={onClose}>
      <Card className={classes.root}>
        <CardHeader
          title="Crosstalk Measurement"
          subheader={moment(crosstalkMeasurement.measurementTime).format(
            'MMM Do YYYY, h:mm:ss a'
          )}
        />
        <CardContent style={{ paddingTop: 0 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CrosstalkChart
                radarValueCollections={
                  crosstalkMeasurement.radarValueCollections
                }
                radarGroup={CROSSTALK_RADAR_GROUPS.LEFT_ENTRY}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CrosstalkChart
                radarValueCollections={
                  crosstalkMeasurement.radarValueCollections
                }
                radarGroup={CROSSTALK_RADAR_GROUPS.RIGHT_ENTRY}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CrosstalkChart
                radarValueCollections={
                  crosstalkMeasurement.radarValueCollections
                }
                radarGroup={CROSSTALK_RADAR_GROUPS.LEFT_EXIT}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CrosstalkChart
                radarValueCollections={
                  crosstalkMeasurement.radarValueCollections
                }
                radarGroup={CROSSTALK_RADAR_GROUPS.RIGHT_EXIT}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default CrosstalkMeasurementDetailsModal;
