import * as actionTypes from 'actions';

const initialState = {
  open: false,
  loadingModels: false,
  loadingModelSets: false,
  models: [],
  modelSets: [],
  search: '',
  tab: 'models'
};

const selectModelReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECT_MODEL_OPEN: {
      return {
        ...state,
        open: action.payload,
        search: initialState.search,
        tab: initialState.tab
      };
    }

    case actionTypes.SET_SELECT_MODEL_SEARCH: {
      return {
        ...state,
        search: action.payload
      };
    }

    case actionTypes.SET_SELECT_MODEL_TAB: {
      return {
        ...state,
        tab: action.payload
      };
    }

    case actionTypes.FIND_SELECT_MODEL_MODELS_PENDING: {
      return {
        ...state,
        models: initialState.models,
        loadingModels: true
      };
    }

    case actionTypes.FIND_SELECT_MODEL_MODELS_FULFILLED: {
      return {
        ...state,
        models: action.payload.data.result,
        loadingModels: false
      };
    }

    case actionTypes.FIND_SELECT_MODEL_MODEL_SETS_PENDING: {
      return {
        ...state,
        modelSets: initialState.modelSets,
        loadingModelSets: true
      };
    }

    case actionTypes.FIND_SELECT_MODEL_MODEL_SETS_FULFILLED: {
      return {
        ...state,
        modelSets: action.payload.data.result,
        loadingModelSets: false
      };
    }

    default: {
      return state;
    }
  }
};

export default selectModelReducer;
