import ws from '../utils/ws';

/* Actions */

export const openAiBatchSubmissionPaginatedList = (
  pageNumber,
  pageSize
) => dispatch =>
  dispatch({
    type: OPEN_AI_BATCH_SUBMISSIONS_PAGINATED_LIST,
    payload: ws.get(
      `/open_ai/batch_submissions/page/${pageNumber}/rows/${pageSize}`
    )
  });

/* Constants */

export const OPEN_AI_BATCH_SUBMISSIONS_PAGINATED_LIST =
  'OPEN_AI_BATCH_SUBMISSIONS_PAGINATED_LIST';
export const OPEN_AI_BATCH_SUBMISSIONS_PAGINATED_LIST_PENDING =
  'OPEN_AI_BATCH_SUBMISSIONS_PAGINATED_LIST_PENDING';
export const OPEN_AI_BATCH_SUBMISSIONS_PAGINATED_LIST_FULFILLED =
  'OPEN_AI_BATCH_SUBMISSIONS_PAGINATED_LIST_FULFILLED';
