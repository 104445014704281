import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  LinearProgress,
  colors,
  ButtonGroup,
  Box
} from '@material-ui/core';
import { CHART_COLORS, RADAR_VERTICAL_LOCATIONS } from 'common/constants';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment-timezone';
import { he } from 'date-fns/locale';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  }
}));

const CrosstalkChart = props => {
  const { radarGroup, radarValueCollections } = props;

  const classes = useStyles();

  console.log('Radar Group: ', radarGroup);
  console.log('Radar Value Collections: ', radarValueCollections);

  const radarGroupValueCollections = radarValueCollections.filter(
    collection => radarGroup.locations.indexOf(collection.radarLocation) > -1
  );

  console.log(
    'Radar Group Value Collections: ',
    radarGroupValueCollections.length
  );

  const options = {
    chart: {
      height: 300,
      zoomType: 'x'
    },
    title: {
      text: null
    },
    tooltip: {
      valueDecimals: 8
    },
    series: [],
    xAxis: {
      title: null
    },
    yAxis: {
      title: null
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false
        }
      }
    }
  };

  const chartSeries = [];

  for (let i = 0; i < radarGroupValueCollections.length; i++) {
    const radarGroupValueCollection = radarGroupValueCollections[i];
    chartSeries.push({
      type: 'spline',
      color: CHART_COLORS[i],
      name:
        RADAR_VERTICAL_LOCATIONS[
          radarGroupValueCollection.radarLocation.split('_')[1]
        ],
      data: radarGroupValueCollection.values.map((value, j) => ({
        x: j,
        y: value
      }))
    });
  }

  options.series = chartSeries;

  return (
    <Card>
      <CardHeader
        subheader={radarGroup.name}
        action={
          <Box
            style={{
              display: 'flex',
              marginTop: 12,
              fontFamily: 'sans-serif',
              fontSize: 12,
              fontWeight: 'bold'
            }}>
            <Box style={{ marginLeft: 12, display: 'flex' }}>
              <Box
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 10,
                  marginTop: 1,
                  marginRight: 6,
                  backgroundColor: CHART_COLORS[0]
                }}
              />
              Top
            </Box>
            <Box style={{ marginLeft: 12, display: 'flex' }}>
              {' '}
              <Box
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 10,
                  marginTop: 1,
                  marginRight: 6,
                  backgroundColor: CHART_COLORS[1]
                }}
              />
              Middle
            </Box>
            <Box style={{ marginLeft: 12, display: 'flex' }}>
              {' '}
              <Box
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 10,
                  marginTop: 1,
                  marginRight: 6,
                  backgroundColor: CHART_COLORS[2]
                }}
              />
              Bottom
            </Box>
          </Box>
        }
      />
      <CardContent className={classes.content}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </CardContent>
    </Card>
  );
};

export default CrosstalkChart;
