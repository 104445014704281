import * as actionTypes from 'actions';

const initialState = {
  pageNumber: 1,
  rowCount: 0,
  loading: false,
  items: [],
  pageSize: 250,
  filterItemCount: null,
  filter: {
    search: '',
    showConnected: false,
    missingTailScaleConfig: false
  },
  customers: [],
  customersLoading: false,
  masterVersions: [],
  masterVersionsLoading: false,
  venueStateCounts: {},
  venueStateCountsLoading: false,
  asOfTime: null
};

const PAGES = ['CELL_LIST', 'TRUESCAN_LIST'];

const cellListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CELLS_PAGINATED_LIST_PENDING: {
      return {
        ...state,
        items: initialState.items,
        loading: true
      };
    }

    case actionTypes.CELLS_PAGINATED_LIST_FULFILLED:
    case actionTypes.CELLS_PAGINATED_LIST_SILENT_FULFILLED: {
      return {
        ...state,
        ...action.payload.data.result,
        filter: {
          ...initialState.filter,
          ...action.payload.data.result.filter
        },
        loading: false,
        asOfTime: action.payload.data.result.asOfTime
      };
    }

    case actionTypes.SET_FILTER_VALUE: {
      let result;
      if (PAGES.indexOf(action.payload.pageName) > -1) {
        result = {
          ...state,
          filter: {
            ...state.filter,
            ...action.payload.value
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.SET_PAGE_SIZE: {
      let result;
      if (PAGES.indexOf(action.payload.pageName) > -1) {
        result = {
          ...state,
          pageSize: action.payload.pageSize
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.CLEAR_FILTER_VALUES: {
      let result;
      if (PAGES.indexOf(action.payload.pageName)) {
        result = {
          ...state,
          filter: {
            ...initialState.filter,
            search: state.filter.search
          },
          filterItemCount: initialState.filterItemCount
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.GET_FILTER_VALUES_FULFILLED: {
      let result;
      if (
        action.payload.data.result &&
        PAGES.indexOf(action.payload.data.result.page) > -1
      ) {
        result = {
          ...state,
          filterItemCount: action.payload.data.result.filter.filterItemCount,
          filter: {
            ...initialState.filter,
            ...action.payload.data.result.filter
          }
        };
      } else {
        result = {
          ...state
        };
      }

      return result;
    }

    case actionTypes.FIND_CUSTOMERS_PENDING: {
      return {
        ...state,
        customers: initialState.customers,
        customersLoading: true
      };
    }

    case actionTypes.FIND_CUSTOMERS_FULFILLED: {
      return {
        ...state,
        customers: action.payload.data,
        customersLoading: false
      };
    }

    case actionTypes.FIND_CELL_VENUE_STATE_COUNTS_PENDING: {
      return {
        ...state,
        venueStateCounts: initialState.venueStateCounts,
        venueStateCountsLoading: true
      };
    }

    case actionTypes.FIND_CELL_VENUE_STATE_COUNTS_FULFILLED: {
      return {
        ...state,
        venueStateCounts:
          action.payload && action.payload.data && action.payload.data.result
            ? action.payload.data.result
            : initialState.venueStateCounts,
        venueStateCountsLoading: false
      };
    }

    case actionTypes.FIND_MASTER_VERSIONS_PENDING: {
      return {
        ...state,
        masterVersions: initialState.customers,
        masterVersionsLoading: true
      };
    }

    case actionTypes.FIND_MASTER_VERSIONS_FULFILLED: {
      return {
        ...state,
        masterVersions: action.payload.data.result,
        masterVersionsLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default cellListReducer;
