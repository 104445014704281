import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, colors } from '@material-ui/core';
import Configuration from 'config';
import Snowfall from 'react-snowfall';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  },
  siteLogo: {
    height: 24,
    display: 'block'
  },
  env_dev: {
    backgroundColor: colors.blueGrey[600]
  },
  env_uat: {
    backgroundColor: colors.green[600]
  },
  env_prod: {
    backgroundColor: '#08102A'
  }
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const environmentCode = Configuration.getConfig().code;

  return (
    <AppBar
      {...rest}
      className={clsx(
        classes.root,
        className,
        classes['env_' + environmentCode]
      )}
      color="primary"
      // style={{
      //   backgroundImage: 'url(/images/holidays.png)',
      //   backgroundRepeat: 'repeat-x',
      //   backgroundSize: 450
      // }}
    >
      {/* <Snowfall snowflakeCount={20} /> */}
      <Toolbar>
        <RouterLink to="/">
          <img
            className={classes.siteLogo}
            alt="Logo"
            src={'/images/logos/logo-white.png'}
          />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
