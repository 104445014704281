/* eslint-disable no-undef */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  LinearProgress,
  Button,
  CircularProgress,
  Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  findEventVideoEvent,
  encodeEventWebVideos,
  findEventWebVideos
} from 'actions';

const useStyles = makeStyles(theme => ({
  videoContainer: {
    outline: 'none',
    display: 'block',
    '& video': {
      pointerEvents: 'none'
    }
  },
  encodingMessage: {
    textAlign: 'center',
    padding: 26,
    '& div': {
      marginTop: 10
    }
  },
  playbackControls: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  rateControl: {
    marginLeft: theme.spacing(1) / 2,
    marginRight: theme.spacing(1) / 2,
    padding: 0
  },
  videoLoadingProgress: {
    marginBottom: -4
  }
}));

const EventVideo = props => {
  const {
    eventId,
    displayPlaybackRate,
    width,
    height,
    loop,
    autoPlay,
    handleVideoEnd,
    handleVideoLoadStart,
    handleVideoLoaded,
    handleAdjustPlaybackRate,
    defaultPlaybackRate,
    eventVideoRef,
    playbackRate,
    ...rest
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    event,
    webEncodingVideos,
    videos,
    webVideosLoading,
    webVideosLoaded
  } = useSelector(state => state.eventVideo);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      dispatch(findEventVideoEvent(eventId));
    }

    return () => {
      mounted = false;
    };
  }, [eventId]);

  if (event.id && !event.loading && !webEncodingVideos && !webVideosLoading) {
    if (event.webVideosEncoded !== true) {
      dispatch(encodeEventWebVideos(eventId));
    } else if (!webVideosLoaded) {
      dispatch(findEventWebVideos(eventId));
    }
  }

  const loadStart = () => {
    handleVideoLoadStart && handleVideoLoadStart();
  };

  const dataLoaded = () => {
    handleVideoLoaded && handleVideoLoaded();
  };

  const ended = () => {
    handleVideoEnd && handleVideoEnd();
  };

  const RateControl = props => (
    <Button
      className={classes.rateControl}
      variant={playbackRate === props.rate ? 'outlined' : 'text'}
      size="small"
      onClick={() => handleAdjustPlaybackRate(props.rate)}>
      {props.rate === 0.5 ? '1/2' : props.rate}x
    </Button>
  );

  return (
    <div {...rest}>
      {event.loading && (
        <LinearProgress className={classes.videoLoadingProgress} />
      )}

      {event.id && event.webVideosEncoded && videos.length > 0 && (
        <>
          <div>
            {videos.map(video => (
              <video
                ref={eventVideoRef}
                disablePictureInPicture
                controlsList="nodownload"
                controls
                autoPlay={
                  autoPlay !== undefined && autoPlay != null ? autoPlay : true
                }
                loop={loop !== undefined && loop != null ? loop : true}
                muted
                playsInline
                onLoadStart={loadStart}
                onLoadedData={dataLoaded}
                onEnded={ended}
                style={{ width: width, height: height }}
                type="video/mp4"
                key={video.key}
                src={video.preSignedDownloadUrl}
              />
            ))}
          </div>
          {displayPlaybackRate && (
            <div className={classes.playbackControls}>
              <RateControl rate={0.5} />
              <RateControl rate={1} />
              <RateControl rate={2} />
              <RateControl rate={3} />
              <RateControl rate={4} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

EventVideo.propTypes = {
  eventId: PropTypes.string
};

export default EventVideo;
