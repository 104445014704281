import * as actionTypes from 'actions';

const initialState = {
  modelAnalysisComparison: {},
  loading: false,
  silentlyLoading: false,
  lastUpdatedTime: null
};

const modelAnalysisComparisonReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_MODEL_ANALYSIS_COMPARISON_PENDING: {
      return {
        ...state,
        loading: true,
        modelAnalysisComparison: initialState.modelAnalysisComparison
      };
    }

    case actionTypes.FIND_MODEL_ANALYSIS_COMPARISON_FULFILLED: {
      return {
        ...state,
        loading: false,
        modelAnalysisComparison: action.payload.data.result,
        lastUpdatedTime: new Date().getTime()
      };
    }

    case actionTypes.RECALCULATE_MODEL_ANALYSIS_COMPARISON_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.RECALCULATE_MODEL_ANALYSIS_COMPARISON_FULFILLED: {
      return {
        ...state,
        loading: false,
        modelAnalysisComparison: action.payload.data.result,
        lastUpdatedTime: new Date().getTime()
      };
    }

    case actionTypes.FIND_MODEL_ANALYSIS_COMPARISON_SILENTLY_PENDING: {
      return {
        ...state,
        silentlyLoading: true
      };
    }

    case actionTypes.FIND_MODEL_ANALYSIS_COMPARISON_SILENTLY_FULFILLED: {
      return {
        ...state,
        silentlyLoading: false,
        modelAnalysisComparison: action.payload.data.result,
        lastUpdatedTime: new Date().getTime()
      };
    }

    default: {
      return state;
    }
  }
};

export default modelAnalysisComparisonReducer;
