import ws from '../utils/ws';

/* Actions */

export const knownCarrierPaginatedList = (pageNumber, pageSize) => dispatch =>
  dispatch({
    type: KNOWN_CARRIERS_PAGINATED_LIST,
    payload: ws.get(`/known_carriers/page/${pageNumber}/rows/${pageSize}`)
  });

export const knownCarrierValueUpdate = values => dispatch =>
  dispatch({
    type: CREATE_KNOWN_CARRIER_VALUE_UPDATE,
    payload: values
  });

export const knownCarrierValueReset = () => dispatch =>
  dispatch({
    type: CREATE_KNOWN_CARRIER_VALUE_RESET
  });

export const saveKnownCarrier = knownCarrier => dispatch =>
  dispatch({
    type: SAVE_KNOWN_CARRIER,
    payload: ws.post('/known_carriers/', knownCarrier)
  });

export const deleteKnownCarrier = knownCarrier => dispatch =>
  dispatch({
    type: DELETE_KNOWN_CARRIER,
    payload: ws.post(`/known_carriers/${knownCarrier.id}/delete`)
  });

export const editKnownCarrier = id => dispatch =>
  dispatch({
    type: EDIT_KNOWN_CARRIER,
    payload: ws.get(`/known_carriers/${id}`)
  });

export const findKnownCarriers = () => dispatch =>
  dispatch({
    type: FIND_KNOWN_CARRIERS,
    payload: ws.get('/known_carriers/')
  });

export const findKnownCarrier = id => dispatch =>
  dispatch({
    type: FIND_KNOWN_CARRIER,
    payload: ws.get(`/known_carriers/${id}`)
  });

/* Constants */

export const FIND_KNOWN_CARRIER_CELLS = 'FIND_KNOWN_CARRIERS_CELLS';
export const FIND_KNOWN_CARRIER_CELLS_PENDING =
  'FIND_KNOWN_CARRIERS_CELLS_PENDING';
export const FIND_KNOWN_CARRIER_CELLS_FULFILLED =
  'FIND_KNOWN_CARRIERS_CELLS_FULFILLED';

export const FIND_KNOWN_CARRIER_CUSTOMER_EVENTS =
  'FIND_KNOWN_CARRIER_CUSTOMER_EVENTS';
export const FIND_KNOWN_CARRIER_CUSTOMER_EVENTS_PENDING =
  'FIND_KNOWN_CARRIER_CUSTOMER_EVENTS_PENDING';
export const FIND_KNOWN_CARRIER_CUSTOMER_EVENTS_FULFILLED =
  'FIND_KNOWN_CARRIER_CUSTOMER_EVENTS_FULFILLED';

export const KNOWN_CARRIERS_PAGINATED_LIST = 'KNOWN_CARRIERS_PAGINATED_LIST';
export const KNOWN_CARRIERS_PAGINATED_LIST_PENDING =
  'KNOWN_CARRIERS_PAGINATED_LIST_PENDING';
export const KNOWN_CARRIERS_PAGINATED_LIST_FULFILLED =
  'KNOWN_CARRIERS_PAGINATED_LIST_FULFILLED';

export const CREATE_KNOWN_CARRIER_VALUE_UPDATE =
  'CREATE_KNOWN_CARRIERS_VALUE_UPDATE';
export const CREATE_KNOWN_CARRIER_VALUE_RESET =
  'CREATE_KNOWN_CARRIER_VALUE_RESET';

export const SAVE_KNOWN_CARRIER = 'SAVE_KNOWN_CARRIER';
export const SAVE_KNOWN_CARRIER_PENDING = 'SAVE_KNOWN_CARRIER_PENDING';
export const SAVE_KNOWN_CARRIER_FULFILLED = 'SAVE_KNOWN_CARRIER_FULFILLED';

export const DELETE_KNOWN_CARRIER = 'DELETE_KNOWN_CARRIER';
export const DELETE_KNOWN_CARRIER_PENDING = 'DELETE_KNOWN_CARRIER_PENDING';
export const DELETE_KNOWN_CARRIER_FULFILLED = 'DELETE_KNOWN_CARRIER_FULFILLED';

export const EDIT_KNOWN_CARRIER = 'EDIT_KNOWN_CARRIER';
export const EDIT_KNOWN_CARRIER_FULFILLED = 'EDIT_KNOWN_CARRIER_FULFILLED';

export const FIND_KNOWN_CARRIERS = 'FIND_KNOWN_CARRIERS';
export const FIND_KNOWN_CARRIERS_PENDING = 'FIND_KNOWN_CARRIERS_PENDING';
export const FIND_KNOWN_CARRIERS_FULFILLED = 'FIND_KNOWN_CARRIERS_FULFILLED';

export const FIND_KNOWN_CARRIER = 'FIND_KNOWN_CARRIER';
export const FIND_KNOWN_CARRIER_PENDING = 'FIND_KNOWN_CARRIER_PENDING';
export const FIND_KNOWN_CARRIER_FULFILLED = 'FIND_KNOWN_CARRIER_FULFILLED';
