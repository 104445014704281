import * as actionTypes from 'actions';

const initialState = {
  latestMessage: null
};

const userListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.WEB_SOCKET_MESSAGE_RECEIVED: {
      return {
        ...state,
        latestMessage: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export default userListReducer;
