import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Modal,
  Card,
  Button,
  CardContent,
  CardActions,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 420,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    '& p': {
      textAlign: 'center'
    }
  },
  actions: {
    justifyContent: 'center'
  }
}));

const BroadcastMessage = () => {
  const classes = useStyles();

  const { latestMessage } = useSelector(state => state.webSocket);

  const [message, setMessage] = useState(null);
  const [displayRedreshAction, setDisplayRefreshAction] = useState(false);

  useEffect(() => {
    if (latestMessage != null && message == null) {
      setMessage({
        text: latestMessage.payload.message,
        user: latestMessage.user
      });
      if (latestMessage.payload.type === 'VERSION_REFRESH') {
        setDisplayRefreshAction(true);
      }
    }
  }, [latestMessage]);

  const handleClose = () => {
    setMessage(null);
  };

  const handleRefresh = () => {
    handleClose();
    window.location.reload();
  };

  console.log('=================================');
  console.log('latestMessage:', latestMessage);
  console.log('/================================');

  return (
    <Modal onClose={handleClose} open={message !== null}>
      <Card className={classes.root}>
        <CardContent>
          {message && <Typography component="p">{message.text}</Typography>}
        </CardContent>
        <CardActions className={classes.actions}>
          <Button variant="contained" onClick={handleClose}>
            Dismiss
          </Button>
          {displayRedreshAction && (
            <Button variant="contained" color="primary" onClick={handleRefresh}>
              Refresh
            </Button>
          )}
        </CardActions>
      </Card>
    </Modal>
  );
};

export default BroadcastMessage;
