/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { CELL_SALES_STATUSES } from 'common/constants';
import { Label } from 'components';
import { colors } from '@material-ui/core';

const CellSalesStatus = props => {
  const { cell, ...rest } = props;

  const statusColors = {
    READY_FOR_SALE: colors.blue[600],
    SOLD: colors.green[600],
    LEASED: colors.purple[600],
    RENTAL: colors.teal[600],
    DEMO: colors.lime[600]
  };

  return (
    <div>
      {cell && cell.salesStatus && (
        <Label {...rest} color={statusColors[cell.salesStatus]}>
          {CELL_SALES_STATUSES[cell.salesStatus]}
        </Label>
      )}
    </div>
  );
};

CellSalesStatus.propTypes = {
  cell: PropTypes.object
};

export default CellSalesStatus;
